import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  card: {
    height: 300,
    width: 500,
    margin: "50px auto 50px auto",
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    margin: "auto auto auto auto",
  },
  submitButton: {
    width: "30%",
    margin: "10px auto auto auto",
  },
  editTextField: {
    width: "60%",
    margin: "10px auto auto auto",
  },
  editDropDownFormControl: {
    width: "60%",
    margin: "10px auto auto auto",
  },
  editDropDown: {
    textAlign: "left",
  },
  input: {
    display: "none",
  },
  uploadButton: {
    marginTop: "10px",
  },
});
