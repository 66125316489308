import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  card: {
    width: 275,
    "@media (max-width: 650px)": {
      width: 200,
    },
  },
  skeletonContainer: {
    position: "relative",
    width: 275,
    height: 387,
    "@media (max-width: 650px)": {
      height: 280,
      width: 200,
    },
  },
  skeleton: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  cardContainer: {
    textDecoration: "none",
  },
  title: {
    display: "-webkit-box",
    "-webkit-line-clamp": 4,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "1rem",
    "@media (max-width: 650px)": {
      fontSize: ".75rem",
    },
  },
  titleContainer: {
    height: "5rem",
    "@media (max-width: 650px)": {
      height: "3rem",
    },
  },
});
