import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { isMobile } from "react-device-detect";
import SwiperCore, {
  Autoplay,
  Pagination,
  Navigation,
  EffectFade,
} from "swiper";
import "swiper/swiper-bundle.min.css";
import { findAllBanners } from "services";
import { useStyles } from "./styles";
import video from "./assets/BannerVideo.mp4";

SwiperCore.use([Autoplay, Pagination, Navigation, EffectFade]);

export function Carousel() {
  const classes = useStyles();
  const [bannerList, setBannerList] = useState([]);
  const [blankElementVisibility, setBlankElementVisibility] =
    useState("visible");

  const bannerMarkup = bannerList.map((banner) => (
    <SwiperSlide key={banner.id}>
      <img alt={`Banner ${banner.id}`} width="100%" src={banner.bannerImage} />
    </SwiperSlide>
  ));

  useEffect(() => {
    findAllBanners().then((res) => {
      setBannerList(res.data);
    });
  }, []);

  return (
    <>
      {isMobile ? null : (
        <div
          className={classes.loadingContainer}
          style={{
            visibility: blankElementVisibility,
          }}
        />
      )}
      <div className={classes.container}>
        <Swiper
          initialSlide={0}
          loop={true}
          effect={"fade"}
          centeredSlides={true}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
        >
          {isMobile ? null : (
            <SwiperSlide
              data-swiper-autoplay="10000"
              className={classes.videoSlide}
              key={0}
            >
              <div>
                <div className={classes.dummyContainer}>
                  <video
                    autoPlay="autoplay"
                    loop="loop"
                    muted
                    playsInline
                    onLoadedData={() => {
                      setBlankElementVisibility("hidden");
                    }}
                    className={classes.video}
                  >
                    <source src={video} type="video/mp4" />
                  </video>
                </div>
              </div>
            </SwiperSlide>
          )}
          {bannerMarkup}
        </Swiper>
        <div className={classes.blank} />
      </div>
    </>
  );
}
