import React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { CustomLoadingOverlay } from "./components";
import { columns } from "./constants";
import { useStyles } from "./styles";

export function AssetPanelList({
  assetLinks,
  selectedAsset,
  handleOrderChange,
  assetUploadWait,
}) {
  const classes = useStyles();

  const rows1 = assetLinks.map((asset) => {
    return {
      id: asset.assetUrl,
      name: asset.assetName,
      order: asset.assetOrder,
    };
  });

  return (
    <div className={classes.container}>
      <DataGrid
        components={{
          LoadingOverlay: CustomLoadingOverlay,
        }}
        loading={assetUploadWait}
        rows={rows1}
        columns={columns}
        pageSize={6}
        onCellEditCommit={handleOrderChange}
        onSelectionModelChange={(newSelection) => {
          selectedAsset(newSelection);
        }}
      />
    </div>
  );
}
