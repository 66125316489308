import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  card: {
    width: 750,
    margin: "20px 20px 20px 20px",
    "@media (max-width: 1200px)": {
      width: "500px",
    },
    "@media (max-width: 915px)": {
      width: "400px",
    },
    "@media (max-width: 500px)": {
      width: "325px",
    },
  },
  container: {
    marginLeft: "10px",
  },
  jobTitle: {
    "@media (max-width: 915px)": {
      fontSize: "18px",
    },
    "@media (max-width: 500px)": {
      fontSize: "18px",
    },
  },
  benefits: {
    "@media (max-width: 915px)": {
      fontSize: "12px",
    },
    "@media (max-width: 500px)": {
      fontSize: "12px",
    },
  },
});
