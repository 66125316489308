import React from "react";
import { useStyles } from "./styles";
import { ContactList, InformationList, ProductsList } from "./components";

export function FooterInfo() {
  const classes = useStyles();

  return (
    <div className={classes.componentContainer}>
      <div className={classes.flexGrid}>
        <ContactList />
        <ProductsList />
        <InformationList />
      </div>
    </div>
  );
}
