import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ProductNavigationPanel,
  ProductBreadcrumbs,
  ProductDetailPanel,
  ProductList,
} from "./components";
import { findAllCategories, findAllProducts, getProductById } from "services";
import { useStyles } from "./styles";

export function ProductPage() {
  const classes = useStyles();

  let url = new URL(`${window.location.origin}/products/`);
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let id = params.get("id");
  let category = params.get("category");
  let subcategory = params.get("subcategory");
  let history = useHistory();
  let iconSelected = false;

  const [productId, setProductId] = useState(id);
  const [expanded, setExpanded] = useState([]);
  const [productArray, setProductArray] = useState([]);
  const [visibleProductArray, setVisibleProductArray] = useState([]);
  const [categoriesArray, setCategoriesArray] = useState([]);
  const [productData, setProductData] = useState({});
  const [errorStatus, setErrorStatus] = useState(true);
  const [firstTimeRender, setFirstTimeRender] = useState(true);
  const [breadcrumb, setBreadcrumb] = useState({
    category: null,
    subcategory: null,
    product: null,
  });
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    findAllProducts().then((res) => {
      res.data = res.data.sort((a, b) => (a.priority > b.priority ? 1 : -1));
      setProductArray(
        res.data
          .filter((product) => product.isActive === true)
          .sort((a, b) => (a.priority > b.priority ? 1 : -1))
      );
      if (subcategory) {
        setVisibleProductArray(
          res.data.filter(
            (product) => product.productSubcategory === subcategory
          )
        );
      } else if (category) {
        setVisibleProductArray(
          res.data.filter((product) => product.productCategory === category)
        );
      } else {
        setVisibleProductArray(res.data);
      }
    });
    findAllCategories().then((res) => {
      let arr = [];
      for (let i = 0; i < res.data.length; i++) {
        arr.push(`category-${res.data[i].productCategory}`);
      }
      if (subcategory) {
        arr.push(`subcategory-${subcategory}`);
      }
      setExpanded([...expanded, ...arr]);
      setCategoriesArray(res.data);

      if (category) {
        setBreadcrumb({
          category: category,
          subcategory: null,
          product: null,
        });
      } else if (subcategory) {
        setBreadcrumb({
          category: res.data.filter((category) =>
            category.productSubCategories.includes(subcategory)
          )[0].productCategory,
          subcategory: subcategory,
          product: null,
        });
      }
    });
  }, []);

  useEffect(() => {
    setProductData({});
    if (productId) {
      getProductById(productId).then((res) => {
        setProductData(res);
        if (Object.keys(res).length === 0) {
          setErrorStatus(true);
        } else {
          setErrorStatus(false);
        }
        if (id) {
          setBreadcrumb({
            category: res.productCategory,
            subcategory: res.productSubcategory,
            product: res.productName,
          });
        }
      });
    } else {
      setErrorStatus(false);
    }
  }, [productId, id]);

  useEffect(() => {
    if (!firstTimeRender && history.action === "POP") {
      window.location.reload();
    }
    if (!firstTimeRender) {
      if (category) {
        setBreadcrumb({
          category: category,
          subcategory: null,
          product: null,
        });
      } else if (subcategory) {
        setBreadcrumb({
          category: categoriesArray.filter((category) =>
            category.productSubCategories.includes(subcategory)
          )[0].productCategory,
          subcategory: subcategory,
          product: null,
        });
      } else if (id) {
        setBreadcrumb({
          category: productData.productCategory,
          subcategory: productData.productSubcategory,
          product: productData.productName,
        });
      }
    }
    setFirstTimeRender(false);
  }, [subcategory, category, id]);

  const handleTabChange = (event, tabValue) => {
    setTabValue(tabValue);
  };

  const handleIconClick = () => {
    iconSelected = true;
  };

  const handleLabelClick = () => {
    iconSelected = false;
  };

  const handleToggle = (e, nodeIds) => {
    if (iconSelected) {
      setExpanded(nodeIds);
    }
  };

  const handleSelect = (e, nodeIds) => {
    if (!iconSelected) {
      if (!nodeIds.includes("subcategory") && !nodeIds.includes("category")) {
        setProductId(nodeIds);
        params.delete("category");
        params.delete("subcategory");
        params.set("id", nodeIds);
        url.search = params.toString();
        history.push(`${url.pathname}${url.search}`);
      } else if (nodeIds.includes("subcategory")) {
        setProductId("");
        params.delete("category");
        params.delete("id");
        params.set("subcategory", nodeIds.replace("subcategory-", ""));
        url.search = params.toString();
        history.push(`${url.pathname}${url.search}`);
        setExpanded([...expanded, nodeIds]);
        setVisibleProductArray(
          productArray.filter(
            (product) =>
              product.productSubcategory === nodeIds.replace("subcategory-", "")
          )
        );
      } else if (nodeIds.includes("category")) {
        setProductId("");
        params.delete("subcategory");
        params.delete("id");
        params.set("category", nodeIds.replace("category-", ""));
        url.search = params.toString();
        history.push(`${url.pathname}${url.search}`);
        setVisibleProductArray(
          productArray.filter(
            (product) =>
              product.productCategory === nodeIds.replace("category-", "")
          )
        );
        setExpanded([...expanded, nodeIds]);
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <ProductNavigationPanel
          handleLabelClick={handleLabelClick}
          handleIconClick={handleIconClick}
          handleToggle={handleToggle}
          handleSelect={handleSelect}
          productArray={productArray}
          categoriesArray={categoriesArray}
          expanded={expanded}
        />
        <div className={classes.column}>
          <div className={classes.breadcrumbs}>
            <ProductBreadcrumbs
              breadcrumb={breadcrumb}
              url={url}
              errorStatus={errorStatus}
            />
          </div>
          {productId ? (
            <div className={classes.productDetailPanel}>
              <ProductDetailPanel
                productData={productData}
                errorStatus={errorStatus}
                tabValue={tabValue}
                handleTabChange={handleTabChange}
              />
            </div>
          ) : (
            <ProductList
              setProductId={setProductId}
              productArray={visibleProductArray}
            />
          )}
        </div>
      </div>
    </div>
  );
}
