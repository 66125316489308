import React from "react";
import {
  AboutUsHome,
  Carousel,
  CertificationsHome,
  ContactUsHome,
  FeaturedProducts,
  CaseStudiesHome,
} from "./components";

export function HomePage() {
  return (
    <>
      <Carousel />
      <FeaturedProducts />
      <CaseStudiesHome />
      <AboutUsHome />
      <CertificationsHome />
      <ContactUsHome />
    </>
  );
}
