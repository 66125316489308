import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  card: {
    height: 300,
    width: 500,
    margin: "50px auto 50px auto",
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    margin: "auto auto auto auto",
  },
  margin: {
    display: "inline-block",
    width: "90%",
    height: "80%",
  },
  link: {
    textDecoration: "none",
    color: "white",
  },
  editButton: {
    margin: "10px auto auto auto",
  },
}));
