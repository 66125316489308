import React, { useEffect, useState } from "react";
import {
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
} from "@material-ui/icons";
import { TreeView } from "@material-ui/lab";
import { findAllCategories, findAllProducts } from "services";
import { useStyles } from "./styles";
import { useHistory } from "react-router-dom";
import { TreeItemContainer } from "./components";

export function ProductNavigationPanel() {
  const classes = useStyles();
  let url = new URL(`${window.location.origin}/products/`);
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let subcategory = params.get("subcategory");
  let history = useHistory();
  let iconSelected = false;

  const [expanded, setExpanded] = useState([]);
  const [productArray, setProductArray] = useState([]);
  const [categoriesArray, setCategoriesArray] = useState([]);

  const handleIconClick = () => {
    iconSelected = true;
  };

  const handleLabelClick = () => {
    iconSelected = false;
  };

  const handleToggle = (e, nodeIds) => {
    if (iconSelected) {
      setExpanded(nodeIds);
    }
  };

  const handleSelect = (e, nodeIds) => {
    if (!iconSelected) {
      if (!nodeIds.includes("subcategory") && !nodeIds.includes("category")) {
        params.delete("category");
        params.delete("subcategory");
        params.set("id", nodeIds);
        url.search = params.toString();
        history.push(`${url.pathname}${url.search}`);
      } else if (nodeIds.includes("subcategory")) {
        params.delete("category");
        params.delete("id");
        params.set("subcategory", nodeIds.replace("subcategory-", ""));
        url.search = params.toString();
        history.push(`${url.pathname}${url.search}`);
        setExpanded([...expanded, nodeIds]);
      } else if (nodeIds.includes("category")) {
        params.delete("subcategory");
        params.delete("id");
        params.set("category", nodeIds.replace("category-", ""));
        url.search = params.toString();
        history.push(`${url.pathname}${url.search}`);
        setExpanded([...expanded, nodeIds]);
      }
    }
  };

  useEffect(() => {
    findAllProducts().then((res) => {
      setProductArray(res.data.filter((product) => product.isActive === true));
    });
    findAllCategories().then((res) => {
      let arr = [];
      for (let i = 0; i < res.data.length; i++) {
        arr.push(`category-${res.data[i].productCategory}`);
      }
      if (subcategory) {
        arr.push(`subcategory-${subcategory}`);
      }
      setExpanded([...expanded, ...arr]);
      setCategoriesArray(res.data);
    });
  }, []);

  return (
    <div className={classes.container}>
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
      >
        <TreeItemContainer
          categoriesArray={categoriesArray}
          productArray={productArray}
          handleIconClick={handleIconClick}
          handleLabelClick={handleLabelClick}
        />
      </TreeView>
    </div>
  );
}
