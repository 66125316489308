import React, { useState } from "react";
import { Tooltip, TextField } from "@material-ui/core";
import { Info as InfoIcon } from "@material-ui/icons";
import { VideoPanel } from "./components/";
import { useStyles } from "./styles";
import { text } from "./text";

export function VideoTab({ productVideoId, setProductVideoId, index }) {
  const classes = useStyles();

  const [id, setId] = useState(productVideoId[index]);

  const validIndex = index < productVideoId.length;

  if (validIndex) {
    return (
      <div className={classes.column}>
        <div className={classes.row}>
          <TextField
            id="standard-required"
            label="Product YouTube ID"
            value={id}
            onChange={(e) => {
              productVideoId[index] = e.target.value;
              setProductVideoId(productVideoId);
              setId(e.target.value);
            }}
            className={classes.editTextField}
          />
          <Tooltip
            title={<div style={{ fontSize: "15px" }}>{text.info}</div>}
            placement="right"
          >
            <InfoIcon className={classes.info} />
          </Tooltip>
        </div>
        <div className={classes.videoDimensions}>
          {productVideoId[index].length === 11 ? (
            <VideoPanel embedId={productVideoId[index]} />
          ) : (
            <div className={classes.emptyDiv}>
              <p>Video IDs must have 11 characters.</p>
              <p>
                Videos not meeting this requirement will not appear on the
                website.
              </p>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return null;
  }
}
