import React from "react";
import { MenuItem, Select, InputLabel, FormControl } from "@material-ui/core";
import { useStyles } from "./styles";
import { text } from "./text";

export function CategoryDropDown({
  productCategory,
  setProductCategory,
  categoryList,
}) {
  const classes = useStyles();

  const categoryElements = categoryList.map((categoryObject) => {
    return (
      <MenuItem
        key={categoryObject.categoryId}
        value={categoryObject.productCategory}
      >
        {categoryObject.productCategory}
      </MenuItem>
    );
  });

  return (
    <FormControl className={classes.editDropDownFormControl}>
      <InputLabel id="product-category-label">{text.category}</InputLabel>
      <Select
        className={classes.editDropDown}
        labelId="product-category-label"
        value={productCategory}
        onChange={(e) => setProductCategory(e.target.value)}
      >
        {categoryElements}
      </Select>
    </FormControl>
  );
}
