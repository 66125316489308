import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  link: {
    textDecoration: "none",
    color: "black",
    "&:visited": {
      color: "black",
    },
  },
  headerText: {
    color: "#110884",
  },
});
