import React from "react";
import { useStyles } from "./styles";
import { text } from "./text";
import { links } from "./constants";

export function CertificationsHome() {
  const classes = useStyles();

  return (
    <div className={classes.componentContainer}>
      <h1 className={classes.header}>{text.header}</h1>
      <div className={classes.flexGrid}>
        <img className={classes.logoImage} alt={text.etl} src={links.etl} />
        <img className={classes.logoImage} alt={text.ul} src={links.ul} />
        <img className={classes.logoImage} alt={text.ite} src={links.ite} />
        <img className={classes.dlcLogoImage} alt={text.dlc} src={links.dlc} />
      </div>
    </div>
  );
}
