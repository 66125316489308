import React from "react";
import { UserPanelList } from "./components";
import { Button } from "@material-ui/core";
import { useStyles } from "./styles";
import { text } from "./text";

export function UserPanel({
  userArray,
  selectedUser,
  gridSelection,
  deleteUser,
}) {
  const classes = useStyles();

  return (
    <div className={classes.userPanel}>
      <UserPanelList userArray={userArray} selectedUser={selectedUser} />
      <Button
        className={classes.deleteButton}
        variant="contained"
        color="primary"
        onClick={() => {
          deleteUser(gridSelection);
        }}
        disabled={Object.keys(gridSelection).length === 0 ? true : false}
      >
        {text.button}
      </Button>
    </div>
  );
}
