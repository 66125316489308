import React from "react";
import { CardActionArea, CardContent, Card } from "@material-ui/core";
import { useStyles } from "./styles";
import { text } from "./text";
import { links } from "./constants";
import apply from "./assets/apply.png";

export function CareerCard() {
  const classes = useStyles();

  return (
    <div className={classes.link}>
      <Card className={classes.card}>
        <CardActionArea href={links.job} target="_blank">
          <CardContent>
            <div className={classes.row}>
              <img src={apply} alt={text.imgAlt} />
              <div className={classes.container}>
                <h1 className={classes.jobTitle}>{text.jobTitle}</h1>
                <h4 className={classes.benefits}>{text.pay}</h4>
                <h4 className={classes.benefits}>{text.benefits}</h4>
              </div>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
}
