import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  flexGrid: {
    display: "flex",
    alignContent: "space-around",
    justifyContent: "space-around",
    flexWrap: "wrap",
    "@media (max-width: 650px)": {
      flexDirection: "column",
      alignContent: "flex-start",
    },
  },
  column: {
    flexDirection: "row",
    flexBasis: "100%",
    flex: 1,
  },
  row: {
    flexDirection: "column",
    flexWrap: "wrap",
  },
  componentContainer: {
    borderTop: "solid 1px black",
    height: "15px",
    "@media (max-width: 650px)": {
      height: "60px",
    },
    "@media (max-width: 280px)": {
      height: "90px",
    },
  },
  bottomRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "@media (max-width: 650px)": {
      flexDirection: "row",
      display: "flex",
      justifyContent: "space-between",
    },
  },
  link: {
    textDecoration: "none",
    color: "black",
    "&:visited": {
      color: "black",
    },
  },
  copyrightText: {
    width: "245px",
    fontSize: "12px",
  },
  privacyText: {
    width: "228px",
    fontSize: "12px",
  },
  termsText: {
    width: "150px",
    fontSize: "12px",
  },
});
