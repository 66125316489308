import React, { useEffect, useState } from "react";
import { ContactForm, ContactInfo } from "./components";
import { ProductNavigationPanel } from "../Shared";
import { sendEmail } from "services";
import { useStyles } from "./styles";

export function Contact() {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("us");
  const [emailAddress, setEmailAddress] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [disableContactSubmit, setDisableContactSubmit] = useState(true);
  const [submitConfirmation, setSubmitConfirmation] = useState(false);

  const emailRegex = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  );

  useEffect(() => {
    // eslint-disable-next-line no-useless-escape
    const phoneNumberScrubbed = phoneNumber.replace(/\D/g, "");
    let isValidPhoneNumber = false;

    if (
      ["us", "ca"].includes(countryCode) &&
      phoneNumberScrubbed.length === 11
    ) {
      isValidPhoneNumber = true;
    } else if (
      !["us", "ca"].includes(countryCode) &&
      phoneNumberScrubbed.length > 5
    ) {
      isValidPhoneNumber = true;
    } else {
      isValidPhoneNumber = false;
    }

    const isValidEmailAddress = emailRegex.test(emailAddress);
    const isValidEmailBody = emailBody.length > 0 ? true : false;
    const isValidName = name.length > 1 ? true : false;

    if (
      isValidPhoneNumber &&
      isValidEmailAddress &&
      isValidEmailBody &&
      isValidName
    ) {
      setDisableContactSubmit(false);
    } else {
      setDisableContactSubmit(true);
    }
  }, [emailBody, name, phoneNumber, emailAddress]);

  const handlePhoneNumber = (value, country) => {
    setPhoneNumber(value);
    setCountryCode(country.countryCode);
  };

  const submitContactForm = (e) => {
    e.preventDefault();
    let details = {
      name: name,
      emailAddress: emailAddress,
      emailBody: emailBody,
      phoneNumber: phoneNumber,
    };
    sendEmail(details);
    setName("");
    setEmailAddress("");
    setEmailBody("");
    setPhoneNumber("");
    setDisableContactSubmit(true);
    setSubmitConfirmation(true);
    setTimeout(() => {
      setSubmitConfirmation(false);
    }, 10000);
  };

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <div className={classes.hidden}>
          <ProductNavigationPanel />
        </div>
        <div>
          <div className={classes.contactBlocks}>
            <ContactForm
              handlePhoneNumber={handlePhoneNumber}
              setName={setName}
              name={name}
              phoneNumber={phoneNumber}
              emailAddress={emailAddress}
              emailBody={emailBody}
              setEmailAddress={setEmailAddress}
              setEmailBody={setEmailBody}
              submitContactForm={submitContactForm}
              disableContactSubmit={disableContactSubmit}
              submitConfirmation={submitConfirmation}
            />
            <ContactInfo />
          </div>
        </div>
      </div>
    </div>
  );
}
