import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  button: {
    textDecoration: "none",
    color: "rgb(130, 130, 130)",
    fontSize: "20px",
    fontWeight: "bold",
    cursor: "pointer",
    margin: "0 1rem",
  },
});
