import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  card: {
    height: 250,
    width: 500,
    margin: "50px auto 50px auto",
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    margin: "auto auto auto auto",
  },
  loginButton: {
    width: "30%",
    margin: "10px auto auto auto",
  },
  loginTextField: {
    width: "60%",
    margin: "10px auto auto auto",
  },
  margin: {
    display: "inline-block",
    width: "90%",
    height: "80%",
  },
});
