import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  button: {
    color: "white",
    fontSize: "1.5vw",
    "@media (max-width:1400px)": {
      fontSize: "2vw",
    },
    "@media (max-width:1000px)": {
      fontSize: "3vw",
    },
    backgroundColor: "#110884",
    "&:hover": {
      backgroundColor: "#110884",
    },
  },
  link: {
    textDecoration: "none",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    margin: "auto",
    width: "50%",
    height: "50%",
    "@media (max-width: 1200px)": {
      flexWrap: "wrap",
    },
  },
  center: {
    margin: "auto",
    width: "600px",
    padding: "10px",
    lineHeight: "3rem",
    minWidth: "65%",
    "@media (max-width:1490px)": {
      lineHeight: "2rem",
    },
    "@media (max-width:1200px)": {
      textAlign: "center",
    },
    "@media (max-width:500px)": {
      lineHeight: "1rem",
    },
  },
  header: {
    fontSize: "2vw",
    "@media (max-width:1400px)": {
      fontSize: "2vw",
    },
    "@media (max-width:1200px)": {
      fontSize: "3vw",
    },
  },
  subheader: {
    fontSize: "1.25vw",
    "@media (max-width:1400px)": {
      fontSize: "1.25vw",
    },
    "@media (max-width:1200px)": {
      fontSize: "2vw",
    },
  },
  image: {
    width: "100%",
    height: "100%",
  },
});
