import React from "react";
import { Button, Card, TextField } from "@material-ui/core";
import { useStyles } from "./styles";
import { text } from "./text";

export function Register({
  setUsername,
  setPassword,
  registerUser,
  password,
  username,
  isRegistrationDisabled,
  validateRegistrationForm,
}) {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <div className={classes.margin}>
        <h1>{text.header}</h1>
        <form className={classes.column} noValidate autoComplete="off">
          <TextField
            id="standard-required"
            label="Username"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
              validateRegistrationForm();
            }}
            className={classes.registerTextField}
          />
          <TextField
            id="standard-required"
            label="Password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              validateRegistrationForm();
            }}
            className={classes.registerTextField}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={registerUser}
            disabled={isRegistrationDisabled}
            className={classes.registerButton}
          >
            {text.button}
          </Button>
        </form>
      </div>
    </Card>
  );
}
