import React from "react";
import { Button, Card } from "@material-ui/core";
import { AssetPanel } from "./components";
import { useStyles } from "./styles";
import { text } from "./text";

export function EditProductAssets({
  handleClickAwayGrid,
  assetUploadWait,
  addProductAsset,
  assetLinks,
  gridSelection,
  selectedAsset,
  deleteAsset,
  handleOrderChange,
}) {
  const classes = useStyles();

  return (
    <div className={classes.column}>
      <Card className={classes.card}>
        <div className={classes.column}>
          <input
            className={classes.input}
            id="file-upload"
            type="file"
            onChange={(e) => {
              addProductAsset(e);
            }}
          />
          <label htmlFor="file-upload">
            <Button
              className={classes.uploadButton}
              variant="contained"
              color="primary"
              component="span"
            >
              {text.upload}
            </Button>
          </label>
          <AssetPanel
            handleClickAwayGrid={handleClickAwayGrid}
            assetLinks={assetLinks}
            selectedAsset={selectedAsset}
            gridSelection={gridSelection}
            deleteAsset={deleteAsset}
            handleOrderChange={handleOrderChange}
            assetUploadWait={assetUploadWait}
          />
        </div>
      </Card>
    </div>
  );
}
