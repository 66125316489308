import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  productItem: {
    width: "900px",
    "@media (max-width:950px)": {
      width: "500px",
      height: "75px",
    },
    "@media (max-width:550px)": {
      width: "300px",
      height: "150px",
    },
    "@media (max-width:350px)": {
      width: "200px",
      height: "100px",
      fontSize: "5px",
    },
    height: "50px",
    border: "1px solid grey",
    marginBottom: "8px",
    backgroundColor: "lightgrey",
    padding: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  image: {
    width: "40px",
    height: "40px",
    marginRight: "10px",
  },
  textContainer: {
    border: "1px black solid",
    backgroundColor: "lightblue",
  },
  productName: {
    fontWeight: "bold",
  },
  categoryText: {
    fontSize: "10px",
    overflowWrap: "break-word",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyItems: "center",
    alignItems: "center",
  },
  button: {
    width: "200px",
    marginBottom: "10px",
  },
  active: {
    margin: "4px",
    padding: "2px",
    border: "1px black solid",
    backgroundColor: "green",
    width: "75px",
    textAlign: "center",
    marginLeft: "auto",
  },
  disabled: {
    margin: "4px",
    padding: "2px",
    border: "1px black solid",
    backgroundColor: "red",
    width: "75px",
    textAlign: "center",
    marginLeft: "auto",
  },
});
