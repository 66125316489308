import React from "react";
import { Card, TextField } from "@material-ui/core";
import { CategoryDropDown, SubCategoryDropDown } from "./components";
import { useStyles } from "./styles";

export function EditProductPanel({
  productName,
  productCategory,
  productSubcategory,
  setProductName,
  setProductCategory,
  setProductSubcategory,
  categoryList,
  subCategoryList,
  productDescription,
  setProductDescription,
}) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <div className={classes.margin}>
        <form noValidate autoComplete="off">
          <div className={classes.row}>
            <TextField
              id="Product Name"
              label="Product Name"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              className={classes.editTextField}
            />
            <CategoryDropDown
              productCategory={productCategory}
              setProductCategory={setProductCategory}
              categoryList={categoryList}
            />
            <SubCategoryDropDown
              productSubcategory={productSubcategory}
              setProductSubcategory={setProductSubcategory}
              subCategoryList={subCategoryList}
            />
          </div>
          <div className={classes.column}>
            <TextField
              id="Description"
              label="Description"
              multiline
              rows={5}
              variant="filled"
              className={classes.editOpenTextField}
              value={productDescription}
              onChange={(e) => setProductDescription(e.target.value)}
            />
          </div>
        </form>
      </div>
    </Card>
  );
}
