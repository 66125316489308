import React from "react";
import PropTypes from "prop-types";
import { AppBar, Tabs, Tab, Typography, Box } from "@material-ui/core";
import { ProductAssetTable } from "./components";
import { useStyles } from "./styles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function ProductTabs({ productData, tabValue, handleTabChange }) {
  const classes = useStyles();

  let techPropsInt = productData.assetLinks.length > 0 ? 1 : null;

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          variant="fullWidth"
          centered
          value={tabValue}
          onChange={handleTabChange}
          aria-label="simple tabs example"
        >
          <Tab label="Info" {...a11yProps(0)} />
          {techPropsInt ? (
            <Tab label="Technical" {...a11yProps(techPropsInt)} />
          ) : null}
        </Tabs>
      </AppBar>
      <TabPanel value={tabValue} index={0}>
        <Typography
          className={classes.description}
          gutterBottom
          variant="subtitle1"
        >
          {productData.productDescription}
        </Typography>
      </TabPanel>
      {techPropsInt ? (
        <TabPanel value={tabValue} index={techPropsInt}>
          <ProductAssetTable assetArray={productData.assetLinks} />
        </TabPanel>
      ) : null}
    </div>
  );
}
