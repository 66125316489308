import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  column: {
    flexDirection: "row",
    flex: 1,
    margin: "20px",
    "@media (max-width: 650px)": {
      margin: "10px",
    },
    textAlign: "left",
  },
  row: {
    flexDirection: "column",
    flexWrap: "wrap",
    "@media (max-width: 650px)": {
      width: "100%",
    },
  },
  list: {
    listStyleType: "none",
    display: "inline-block",
    textAlign: "left",
  },
  line: {
    backgroundColor: "#110884",
    borderWidth: "0",
    height: "1.5px",
    margin: "2px 0px 4px 0px",
    width: "150px",
  },
});
