import React from "react";
import { FooterInfo, FooterCopyright } from "./components";
import { useStyles } from "./styles";

export function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <FooterInfo />
      <FooterCopyright />
    </div>
  );
}
