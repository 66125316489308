import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { ProductMultiImageView, ProductTabs } from "./components";
import { useStyles } from "./styles";

export function ProductDetailPanel({
  productData,
  errorStatus,
  tabValue,
  handleTabChange,
}) {
  const classes = useStyles();

  return (
    <>
      {!errorStatus && productData.isActive ? (
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item>
                <div className={classes.image}>
                  <ProductMultiImageView
                    productLinks={productData.productLinks}
                    productVideoId={productData.productVideoId}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Typography gutterBottom variant="h5">
                      {productData.productName}
                    </Typography>
                    <ProductTabs
                      productData={productData}
                      tabValue={tabValue}
                      handleTabChange={handleTabChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}
