import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { editProductPriority, findAllProducts } from "services";
import { Button } from "@material-ui/core";
import { useStyles } from "./styles";
import { text } from "./text";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const productPriority = async (e, productArray) => {
  e.preventDefault();
  editProductPriority(productArray);
};

function Product({ product, index }) {
  const classes = useStyles();
  return (
    <Draggable draggableId={product.id} index={index}>
      {(provided) => (
        <div
          className={classes.productItem}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <img
            className={classes.image}
            alt={product.productName}
            src={product.productLinks[0].productUrl}
          />
          <div className={classes.column}>
            <div>
              <span className={classes.productName}>{product.productName}</span>
            </div>
            <div>
              <span
                className={classes.categoryText}
              >{`${product.productCategory} > ${product.productSubcategory}`}</span>
            </div>
          </div>
          {product.isActive ? (
            <div className={classes.active}>
              <span>{text.active}</span>
            </div>
          ) : (
            <div className={classes.disabled}>
              <span>{text.disabled}</span>
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
}

const ProductList = React.memo(function ProductList({ products }) {
  return products.map((product, index) => (
    <Product product={product} index={index} key={product.id} />
  ));
});

export function ProductOrder() {
  const [productArray, setProductArray] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    findAllProducts().then((res) => {
      setProductArray(
        res.data.sort((a, b) => (a.priority > b.priority ? 1 : -1))
      );
    });
  }, []);

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const products = reorder(
      productArray,
      result.source.index,
      result.destination.index
    );
    setProductArray(products);
  }

  return (
    <>
      {productArray.length > 0 ? (
        <div className={classes.container}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={(e) => productPriority(e, productArray)}
          >
            {text.update}
          </Button>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <ProductList products={productArray} />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      ) : null}
    </>
  );
}
