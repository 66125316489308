import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  card: {
    height: 300,
    width: 500,
    margin: "50px auto 50px auto",
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    margin: "auto auto auto auto",
  },
  margin: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyItems: "center",
    alignItems: "center",
  },
  link: {
    textDecoration: "none",
    color: "white",
  },
  orderButton: {
    margin: "auto",
  },
});
