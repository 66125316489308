import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";
import {
  EditProductPanel,
  ManageProduct,
  EditProductAssets,
  EditProductVideo,
  EditProductImage,
} from "./components";
import {
  deleteProductById,
  editProductById,
  getProductById,
  uploadProductImage,
  uploadProductAsset,
  findAllCategories,
} from "services";
import { useStyles } from "./styles";

export function EditProductPage() {
  let history = useHistory();

  const classes = useStyles();

  const [productStatus, setProductStatus] = useState("");
  const [productName, setProductName] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [productImageUrl, setProductImageUrl] = useState("");
  const [productSubcategory, setProductSubcategory] = useState("");
  const [errorStatus, setErrorStatus] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [assetLinks, setAssetLinks] = useState([]);
  const [open, setOpen] = useState(false);
  const [snackBarOpen, setSnackbarOpen] = useState(false);
  const [firstTimeRender, setFirstTimeRender] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [productDescription, setProductDescription] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarDuration, setSnackbarDuration] = useState(6000);
  const [saveWarning, setSaveWarning] = useState(false);
  const [imageUploadWait, setImageUploadWait] = useState(false);
  const [assetUploadWait, setAssetUploadWait] = useState(false);
  const [productVideoId, setProductVideoId] = useState([]);
  const [assetLinksUpdate, setAssetLinksUpdate] = useState(false);
  const [productLinks, setProductLinks] = useState([]);
  const [productLinksUpdate, setProductLinksUpdate] = useState(false);
  const [assetGridSelection, setAssetGridSelection] = useState([]);
  const [imageGridSelection, setImageGridSelection] = useState([]);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleActiveSwitch = () => {
    if (isActive) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickAwayAssetGrid = () => {
    setAssetGridSelection([]);
  };

  const handleClickAwayImageGrid = () => {
    setImageGridSelection([]);
  };

  const handleConfirmClose = () => {
    setOpen(false);
    deleteProduct();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOrderChange = (e) => {
    if (e.field) {
      let tempAssetLinks = assetLinks;
      let objIndex = assetLinks.findIndex((obj) => obj.assetUrl === e.id);
      if (e.field === "order") {
        tempAssetLinks[objIndex].assetOrder = parseInt(e.value);
        tempAssetLinks.sort((a, b) =>
          a.assetOrder > b.assetOrder ? 1 : b.assetOrder > a.assetOrder ? -1 : 0
        );
      } else if (e.field === "name") {
        tempAssetLinks[objIndex].assetName = e.value;
      }
      setAssetLinks(tempAssetLinks);
      setAssetLinksUpdate(!assetLinksUpdate);
    }
  };

  const { id } = useParams();

  useEffect(() => {
    if (!firstTimeRender) {
      setSaveWarning(true);
    }
  }, [
    productName,
    productCategory,
    productSubcategory,
    productDescription,
    isActive,
    assetLinks,
    productLinks,
    productVideoId,
    assetLinksUpdate,
    productLinksUpdate,
  ]);

  useEffect(() => {
    getProductById(id).then((res) => {
      if (Object.keys(res).length === 0) {
        setErrorStatus(true);
      } else {
        setProductName(res.productName);
        setProductCategory(res.productCategory);
        setProductLinks(res.productLinks);
        setProductSubcategory(res.productSubcategory);
        setProductDescription(res.productDescription);
        setIsActive(res.isActive);
        setAssetLinks(res.assetLinks);
        setProductVideoId(res.productVideoId);
        setErrorStatus(false);
        setFirstTimeRender(false);
      }
      findAllCategories().then((res) => {
        setCategoryList(res.data);
      });
    }, []);
  }, [id]);

  useEffect(() => {
    if (categoryList.length > 0) {
      const res = categoryList.filter(
        (category) => category.productCategory === productCategory
      );
      if (res.length > 0) {
        setSubCategoryList(res[0].productSubCategories);
      }
    }
  }, [categoryList, productCategory]);

  useEffect(() => {
    setProductSubcategory("");
  }, [productCategory]);

  const editProduct = async (e) => {
    e.preventDefault();
    if (
      productName.length === 0 ||
      productCategory.length === 0 ||
      productSubcategory.length === 0
    ) {
      setSnackbarMessage(
        "Product Name, Category, And Subcategory Can Not Be Blank"
      );
      setSnackbarSeverity("error");
      setSnackbarDuration(6000);
      setSnackbarOpen(true);
    } else if (productLinks.length === 0) {
      setSnackbarMessage(
        "There Must Be At Least One Image Uploaded With This Product Before Saving"
      );
      setSnackbarSeverity("error");
      setSnackbarDuration(6000);
      setSnackbarOpen(true);
    } else {
      editProductById(
        id,
        productName,
        productCategory,
        productSubcategory,
        productLinks,
        productDescription,
        isActive,
        assetLinks,
        productVideoId
      ).then((res) => {
        if (res.data === "success") {
          setSnackbarMessage("Product Updated Successfully");
          setSnackbarSeverity("success");
          setSnackbarDuration(6000);
          setSnackbarOpen(true);
          setSaveWarning(false);
        } else {
          setSnackbarMessage("Failure To Update Product");
          setSnackbarSeverity("error");
          setSnackbarDuration(6000);
          setSnackbarOpen(true);
        }
      });
    }
  };

  const deleteProduct = async () => {
    deleteProductById(id).then(history.push("/productadmin"));
  };

  const addProductImage = async (e) => {
    setImageUploadWait(true);
    await uploadProductImage(e.target.files[0]).then((res) => {
      if (
        res.format.toLowerCase() !== "png" &&
        res.format.toLowerCase() !== "jpg"
      ) {
        setSnackbarMessage(
          "Image Must Be JPG Or PNG Format. Please Upload Again."
        );
        setSnackbarSeverity("error");
        setSnackbarDuration(10000);
        setSnackbarOpen(true);
      } else if (res.width !== res.height) {
        setSnackbarMessage(
          "Image Height & Width Must Be The Same. Please Upload A Square Image."
        );
        setSnackbarSeverity("error");
        setSnackbarDuration(10000);
        setSnackbarOpen(true);
      } else if (res.width < 400 && res.height < 400) {
        setSnackbarMessage(
          "Image Width & Height Must Be Greater Than Or Equal To 400 pixels. Please Upload An Image That Meets This Requirement."
        );
        setSnackbarSeverity("error");
        setSnackbarDuration(20000);
        setSnackbarOpen(true);
      } else if (productLinks.length >= 7) {
        setSnackbarMessage(
          "Maximum Amount of Seven Images Can Be Uploaded. Please Delete An Image And Try Again"
        );
        setSnackbarSeverity("error");
        setSnackbarDuration(20000);
        setSnackbarOpen(true);
      } else {
        setProductLinks([
          ...productLinks,
          {
            productName: res.original_filename,
            productUrl: res.secure_url,
            productOrder: 0,
          },
        ]);
        setSnackbarMessage("Product Image Uploaded Successfully.");
        setSnackbarSeverity("success");
        setSnackbarDuration(6000);
        setSnackbarOpen(true);
      }
      setImageUploadWait(false);
    });
  };

  const addProductAsset = async (e) => {
    setAssetUploadWait(true);
    await uploadProductAsset(e.target.files[0])
      .then((res) => {
        const fileExtension = res.secure_url.toLowerCase().split(".").pop();
        if (fileExtension !== "ies" && fileExtension !== "pdf") {
          setSnackbarMessage(
            "Image Must Be IES Or PDF Format. Please Upload Again."
          );
          setSnackbarSeverity("error");
          setSnackbarDuration(6000);
          setSnackbarOpen(true);
        } else if (
          !assetLinks.some((asset) => asset.assetName === res.original_filename)
        ) {
          setAssetLinks([
            ...assetLinks,
            {
              assetName: res.original_filename,
              assetUrl: res.secure_url,
              assetOrder: 0,
              assetExtension: fileExtension,
            },
          ]);
          setSnackbarMessage("File Uploaded Successfully");
          setSnackbarSeverity("success");
          setSnackbarDuration(6000);
          setSnackbarOpen(true);
        } else {
          setSnackbarMessage(
            "That File Name Already Exists For This Product. Please Upload Again."
          );
          setSnackbarSeverity("error");
          setSnackbarDuration(6000);
          setSnackbarOpen(true);
        }
        setAssetUploadWait(false);
      })
      .then((e.target.value = null));
  };

  const selectedAsset = (row) => {
    setAssetGridSelection(row);
  };

  const deleteAsset = (id) => {
    setAssetLinks(assetLinks.filter((asset) => asset.assetUrl !== id[0]));
    setAssetGridSelection([]);
  };

  const selectedImage = (row) => {
    setImageGridSelection(row);
  };

  const deleteImage = (id) => {
    setProductLinks(
      productLinks.filter((product) => product.productUrl !== id[0])
    );
    setImageGridSelection([]);
  };

  const handleOrderImageChange = (e) => {
    let tempProductLinks = productLinks;
    let objIndex = productLinks.findIndex((obj) => obj.productUrl === e.id);
    tempProductLinks[objIndex].productOrder = parseInt(e.value);
    tempProductLinks.sort((a, b) =>
      a.productOrder > b.productOrder
        ? 1
        : b.productOrder > a.productOrder
        ? -1
        : 0
    );

    setProductLinks(tempProductLinks);
    setProductLinksUpdate(!productLinksUpdate);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <>
      <div className={classes.row}>
        {errorStatus ? (
          <div></div>
        ) : (
          <div className={classes.column}>
            <EditProductPanel
              addProductImage={addProductImage}
              deleteProduct={deleteProduct}
              productName={productName}
              productCategory={productCategory}
              productSubcategory={productSubcategory}
              productImageUrl={productImageUrl}
              productDescription={productDescription}
              setProductDescription={setProductDescription}
              setProductStatus={setProductStatus}
              setProductName={setProductName}
              setProductCategory={setProductCategory}
              setProductImageUrl={setProductImageUrl}
              setProductSubcategory={setProductSubcategory}
              categoryList={categoryList}
              subCategoryList={subCategoryList}
            />
            <div className={classes.row}>
              <div className={classes.column}>
                <EditProductImage
                  addProductImage={addProductImage}
                  productLinks={productLinks}
                  gridSelection={imageGridSelection}
                  selectedProduct={selectedImage}
                  deleteImage={deleteImage}
                  imageUploadWait={imageUploadWait}
                  handleClickAwayGrid={handleClickAwayImageGrid}
                  handleOrderImageChange={handleOrderImageChange}
                />
                <EditProductVideo
                  productVideoId={productVideoId}
                  setProductVideoId={setProductVideoId}
                />
              </div>
              <div className={classes.column}>
                <EditProductAssets
                  addProductAsset={addProductAsset}
                  assetLinks={assetLinks}
                  gridSelection={assetGridSelection}
                  selectedAsset={selectedAsset}
                  deleteAsset={deleteAsset}
                  assetUploadWait={assetUploadWait}
                  handleClickAwayGrid={handleClickAwayAssetGrid}
                  handleOrderChange={handleOrderChange}
                />
                <ManageProduct
                  isActive={isActive}
                  handleActiveSwitch={handleActiveSwitch}
                  handleClickOpen={handleClickOpen}
                  handleClose={handleClose}
                  handleConfirmClose={handleConfirmClose}
                  open={open}
                  editProduct={editProduct}
                  productStatus={productStatus}
                  saveWarning={saveWarning}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={snackBarOpen}
        autoHideDuration={snackbarDuration}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
