export const videos = [
  {
    id: "DXqJ8B5aoFM",
    header: "High Mast JXM-ST-HM Series",
    body: "Since March 2015, our LED High Mast fixtures have been installed throughout the state of Texas, including the metropolitan areas of Houston, Dallas, San Antonio, Corpus Christi, and El Paso. DURALIGHT High Mast fixtures are approved and/or installed in Louisiana, Ohio, Virginia, Wyoming, and many other states across the US. This video shows a comparison between traditional HPS High Mast Fixtures and our DURALIGHT LED fixtures.",
    link: "/products?id=6182999b6d2bef002325aa4f",
  },
  {
    id: "TjJtD_L5_-I",
    header: "Cobra Head DURA-STR Series",
    body: "Our LED Cobra Head fixtures are installed nationwide on roadways, highways, and residential areas across the US. Our fixtures are approved by many state Department of Transportations, city municipalities, and utility/power companies. This video shows our DURA-STR Cobra head, in action, compared to a traditional HPS fixture.",
    link: "/products?id=61814831376dfa0023de1ede",
  },
];
