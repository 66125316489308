import React from "react";
import { CaseCard } from "./components";
import { useStyles } from "./styles";
import { videos } from "./constants";

export function CaseList() {
  const classes = useStyles();

  const caseCardElements = videos.map((video) => {
    return <CaseCard key={video.id} video={video} />;
  });

  return <div className={classes.container}>{caseCardElements}</div>;
}
