import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  container: {
    width: "100%",
    marginTop: "-20px",
  },
  videoContainer: {
    display: "inline-block",
    position: "relative",
    width: "100%",
  },
  video: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
  },
  blank: {
    position: "absolute",
    width: "100%",
    height: ".3%",
    marginTop: "-.3%",
    backgroundColor: "white",
    zIndex: 100,
  },
  loadingContainer: {
    width: "100%",
    height: "40vw",
    position: "absolute",
    zIndex: "100",
    backgroundColor: "white",
  },
});
