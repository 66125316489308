import React from "react";
import { TreeItem } from "@material-ui/lab";
import { useStyles } from "./styles";

export function TreeItemContainer({
  categoriesArray,
  productArray,
  handleIconClick,
  handleLabelClick,
}) {
  let treeObject = [];
  let category;
  let subcategory;
  let product;
  let productsInSubcategory = [];
  let subcategoryTotal = [];
  let productsTotal = [];
  let specificProduct = "";

  const classes = useStyles();

  let allProducts = (
    <TreeItem
      onLabelClick={() => window.location.replace(`/products`)}
      key={`allProducts`}
      nodeId={`allProducts`}
      label={<span className={classes.allProductsText}>All Products</span>}
    />
  );
  treeObject.push(allProducts);

  for (let i = 0; i < categoriesArray.length; i++) {
    for (let j = 0; j < categoriesArray[i].productSubCategories.length; j++) {
      productsInSubcategory = productArray.filter(
        (product) =>
          product.productSubcategory ===
            categoriesArray[i].productSubCategories[j] &&
          product.productCategory === categoriesArray[i].productCategory
      );
      for (let k = 0; k < productsInSubcategory.length; k++) {
        specificProduct = productsInSubcategory[k];
        product = (
          <TreeItem
            classes={{ label: classes.productItem }}
            key={`product-${specificProduct.productName}`}
            nodeId={specificProduct.id}
            label={specificProduct.productName}
          />
        );
        productsTotal.push(product);
      }
      subcategory = (
        <TreeItem
          classes={{ label: classes.subcategoryItem }}
          onIconClick={handleIconClick}
          onLabelClick={handleLabelClick}
          key={`subcategory-${categoriesArray[i].productSubCategories[j]}`}
          nodeId={`subcategory-${categoriesArray[i].productSubCategories[j]}`}
          label={categoriesArray[i].productSubCategories[j]}
        >
          {productsTotal}
        </TreeItem>
      );

      subcategoryTotal.push(subcategory);
      productsTotal = [];
    }
    category = (
      <TreeItem
        classes={{ label: classes.categoryItem }}
        onIconClick={handleIconClick}
        onLabelClick={handleLabelClick}
        key={`category-${categoriesArray[i].productCategory}`}
        nodeId={`category-${categoriesArray[i].productCategory}`}
        label={categoriesArray[i].productCategory}
      >
        {subcategoryTotal}
      </TreeItem>
    );
    treeObject.push(category);

    subcategoryTotal = [];
  }
  return treeObject;
}
