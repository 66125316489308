import React from "react";
import { Button, Card, TextField } from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";
import { useStyles } from "./styles";
import { text } from "./text";
export function ContactForm({
  submitConfirmation,
  emailAddress,
  emailBody,
  phoneNumber,
  name,
  setName,
  handlePhoneNumber,
  submitContactForm,
  setEmailAddress,
  setEmailBody,
  disableContactSubmit,
}) {
  const classes = useStyles();

  return (
    <div className={classes.column}>
      <Card className={classes.card}>
        <div className={classes.margin}>
          <h1 className={classes.header}>{text.header}</h1>
          <div className={classes.greeting}>{text.greeting}</div>
          <form className={classes.column} noValidate autoComplete="off">
            <TextField
              id="name"
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className={classes.editTextField}
            />
            <MuiPhoneNumber
              defaultCountry={"us"}
              value={phoneNumber}
              onChange={(value, country) => handlePhoneNumber(value, country)}
              disableAreaCodes={true}
              countryCodeEditable={false}
              inputClass={classes.phoneTextField}
              dropdownClass={classes.dropDownContainer}
            />

            <TextField
              id="email"
              value={emailAddress}
              label="Email Address"
              onChange={(e) => setEmailAddress(e.target.value)}
              className={classes.editTextField}
            />
            <TextField
              id="description"
              value={emailBody}
              label="Message"
              multiline
              minRows={6}
              variant="filled"
              className={classes.editTextField}
              onChange={(e) => setEmailBody(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={submitContactForm}
              className={classes.submitButton}
              disabled={disableContactSubmit}
            >
              {text.button}
            </Button>
          </form>
          {submitConfirmation ? (
            <h5 className={classes.submission}>{text.submission}</h5>
          ) : null}
        </div>
      </Card>
    </div>
  );
}
