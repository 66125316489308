import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  container: {
    textAlign: "center",
    padding: "2% 5%",
    backgroundColor: "#F0F0F5",
    "@media (max-width:1000px)": {
      padding: "2%",
    },
  },
  flexGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "2rem 0",
    "@media (max-width:1400px)": {
      flexDirection: "column",
    },
  },
  copyText: {
    width: "75%",
    fontSize: "1vw",
    fontWeight: "normal",
    margin: "1rem",
    "@media (max-width:1400px)": {
      fontSize: "1.5vw",
      width: "95%",
    },
    "@media (max-width:1000px)": {
      fontSize: "2.5vw",
      width: "95%",
    },
    "@media (max-width:600px)": {
      fontSize: "3.5vw",
      width: "95%",
    },
  },
  headerText: {
    color: "#110884",
    fontSize: "2vw",
    "@media (max-width:1400px)": {
      fontSize: "2.5vw",
    },
    "@media (max-width:1000px)": {
      fontSize: "3.5vw",
    },
    "@media (max-width:600px)": {
      fontSize: "4.5vw",
    },
  },
  button: {
    color: "white",
    fontSize: "1vw",
    margin: "1rem",
    "@media (max-width:1400px)": {
      fontSize: "2vw",
    },
    "@media (max-width:1000px)": {
      fontSize: "2vw",
    },
    "@media (max-width:600px)": {
      fontSize: "3vw",
    },
    backgroundColor: "#110884",
    "&:hover": {
      backgroundColor: "#110884",
    },
  },
  link: {
    textDecoration: "none",
  },
  contentArea: {
    flexBasis: "30%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  videoContainer: {
    width: "100%",
    flexBasis: "50%",
  },
});
