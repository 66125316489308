import React from "react";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import { text } from "./text";
import { image } from "./constants";
import { useStyles } from "./styles";

export function AboutUsCard() {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <div>
        <CardMedia
          component="img"
          height="50%"
          width="50%"
          image={image.link}
          alt={image.alt}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {text.header}
          </Typography>
          <Typography variant="body2" fontSize="1.2rem">
            {text.copy}
          </Typography>
        </CardContent>
      </div>
    </Card>
  );
}
