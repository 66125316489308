import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  row: {
    display: "flex",
    flexDirection: "row",
    "@media (max-width: 915px)": {
      flexWrap: "wrap",
    },
  },
  column: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  productDetailPanel: {
    marginTop: "20px",
  },
  breadcrumbs: {
    fontSize: "small",
  },
});
