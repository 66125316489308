import React from "react";
import { Button, Card } from "@material-ui/core";
import { ActiveToggle, DeleteConfirmationDialog } from "./components";
import {
  Warning as WarningIcon,
  CheckCircle as CheckCircleIcon,
} from "@material-ui/icons";
import { useStyles } from "./styles";
import { text } from "./text";

export function ManageProduct({
  saveWarning,
  isActive,
  handleActiveSwitch,
  handleClickOpen,
  handleClose,
  handleConfirmClose,
  open,
  editProduct,
  productStatus,
}) {
  const classes = useStyles();

  return (
    <div className={classes.column}>
      <Card className={classes.card}>
        <div className={classes.column}>
          {saveWarning ? (
            <div className={classes.syncIndicator}>
              <WarningIcon style={{ fill: "#FF9801", fontSize: "50px" }} />
              <h3 className={classes.text}>{text.changes}</h3>
            </div>
          ) : (
            <div className={classes.row}>
              <CheckCircleIcon style={{ fill: "#4CAF50", fontSize: "50px" }} />
              <h3 className={classes.text}>{text.updated}</h3>
            </div>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => editProduct(e)}
            className={classes.submitButton}
          >
            {text.save}
          </Button>
          <ActiveToggle
            isActive={isActive}
            handleActiveSwitch={handleActiveSwitch}
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClickOpen}
            className={classes.submitButton}
          >
            {text.delete}
          </Button>
          <DeleteConfirmationDialog
            handleClose={handleClose}
            handleConfirmClose={handleConfirmClose}
            open={open}
          />
          <h5>{productStatus}</h5>
        </div>
      </Card>
    </div>
  );
}
