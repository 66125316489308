import React from "react";
import {
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useStyles } from "./styles";
import { text } from "./text";

export function ChooseEditProductPanel({
  productList,
  handleDropDown,
  selectedProduct,
}) {
  const classes = useStyles();

  let productMenuItems = productList.map((product) => (
    <MenuItem key={product.id} value={product.id}>
      {product.productName}
    </MenuItem>
  ));

  return (
    <div className={classes.column}>
      <Card className={classes.card}>
        <div className={classes.margin}>
          <h1>{text.edit}</h1>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="product-input-label">{text.product}</InputLabel>
            <Select
              id="product-select"
              value={selectedProduct}
              onChange={handleDropDown}
              label="Product"
            >
              {productMenuItems}
            </Select>
            <Button
              variant="contained"
              color="primary"
              className={classes.editButton}
              disabled={selectedProduct === "" ? true : false}
            >
              <Link
                className={classes.link}
                to={`/editproduct/${selectedProduct}`}
              >
                Edit Product
              </Link>
            </Button>
          </FormControl>
        </div>
      </Card>
    </div>
  );
}
