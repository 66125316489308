import axios from "axios";

export const findAllProducts = () => {
  return axios.get(`${process.env.REACT_APP_URL}/getallproducts`, {
    withCredentials: true,
  });
};

export const getProductById = (id) => {
  return axios
    .get(`${process.env.REACT_APP_URL}/product/${id}`, {
      withCredentials: true,
    })
    .then((res) => {
      if (res === {}) {
        return res;
      } else return res.data;
    });
};

export const editProductById = (
  id,
  productName,
  productCategory,
  productSubcategory,
  productLinks,
  productDescription,
  isActive,
  assetLinks,
  productVideoId
) => {
  return axios.post(
    `${process.env.REACT_APP_URL}/productedit`,
    {
      id,
      productName,
      productCategory,
      productSubcategory,
      productLinks,
      productDescription,
      isActive,
      assetLinks,
      productVideoId,
    },
    {
      withCredentials: true,
    }
  );
};

export const editProductPriority = (productArray) => {
  return axios.post(
    `${process.env.REACT_APP_URL}/productpriority`,
    {
      productArray,
    },
    {
      withCredentials: true,
    }
  );
};

export const createProduct = (
  productName,
  productCategory,
  productSubcategory
) => {
  return axios.post(
    `${process.env.REACT_APP_URL}/product`,
    {
      productName,
      productCategory,
      productSubcategory,
      productLinks: [
        {
          productName: "Default Image",
          productUrl:
            "https://res.cloudinary.com/df3ajcohw/image/upload/v1633207965/assets/wbrdgya6hmfssulx6ur3.png",
          productOrder: 0,
        },
      ],
    },
    {
      withCredentials: true,
    }
  );
};

export const deleteProductById = (id) => {
  return axios.post(
    `${process.env.REACT_APP_URL}/deleteproduct`,
    {
      id,
    },
    {
      withCredentials: true,
    }
  );
};
