import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  container: {
    textAlign: "center",
    padding: "3rem 0",
  },
  copyText: {
    width: "50%",
    fontSize: "1vw",
    margin: "1rem auto",
    fontWeight: "normal",
    "@media (max-width:1400px)": {
      fontSize: "1.5vw",
    },
    "@media (max-width:1000px)": {
      fontSize: "2.5vw",
    },
    "@media (max-width:600px)": {
      fontSize: "3.5vw",
    },
  },
  headerText: {
    color: "#110884",
    fontSize: "2vw",
    "@media (max-width:1400px)": {
      fontSize: "2.5vw",
    },
    "@media (max-width:1000px)": {
      fontSize: "3.5vw",
    },
    "@media (max-width:600px)": {
      fontSize: "4.5vw",
    },
  },
  button: {
    color: "white",
    fontSize: "1vw",
    "@media (max-width:1400px)": {
      fontSize: "2vw",
    },
    "@media (max-width:1000px)": {
      fontSize: "2vw",
    },
    "@media (max-width:600px)": {
      fontSize: "3vw",
    },
    backgroundColor: "#110884",
    "&:hover": {
      backgroundColor: "#110884",
    },
  },
  link: {
    textDecoration: "none",
  },
});
