import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./styles";

export function VideoPanel({ embedId }) {
  const classes = useStyles();

  return (
    <div className={classes.videoResponsive}>
      <iframe
        className={classes.iframe}
        width="850"
        height="350"
        src={`https://www.youtube-nocookie.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
}

VideoPanel.propTypes = {
  embedId: PropTypes.string.isRequired,
};
