import React from "react";
import { Button, ClickAwayListener } from "@material-ui/core";
import { AssetPanelList } from "./components";
import { useStyles } from "./styles";
import { text } from "./text";

export function AssetPanel({
  handleClickAwayGrid,
  assetLinks,
  selectedAsset,
  gridSelection,
  deleteAsset,
  handleOrderChange,
  assetUploadWait,
}) {
  const classes = useStyles();

  return (
    <ClickAwayListener onClickAway={handleClickAwayGrid}>
      <div className={classes.userPanel}>
        <AssetPanelList
          handleOrderChange={handleOrderChange}
          assetLinks={assetLinks}
          selectedAsset={selectedAsset}
          assetUploadWait={assetUploadWait}
        />
        <Button
          className={classes.deleteButton}
          variant="contained"
          color="secondary"
          onClick={() => {
            deleteAsset(gridSelection);
          }}
          disabled={Object.keys(gridSelection).length === 0 ? true : false}
        >
          {text.delete}
        </Button>
      </div>
    </ClickAwayListener>
  );
}
