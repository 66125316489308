import React, { useState } from "react";
import { Button, Card, TextField } from "@material-ui/core";
import { login } from "services";
import { useStyles } from "./styles";
import { text } from "./text";

export function Login() {
  const classes = useStyles();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const loginUser = () => {
    login(username, password).then(
      (res) => {
        if (res.data === "success") {
          window.location.href = "/";
        }
      },
      () => {
        console.log("Failure");
      }
    );
  };

  return (
    <div className={classes.column}>
      <Card className={classes.card}>
        <div className={classes.margin}>
          <h1>{text.login}</h1>
          <form className={classes.column} noValidate autoComplete="off">
            <TextField
              id="username"
              label="Username"
              onChange={(e) => setUsername(e.target.value)}
              className={classes.loginTextField}
            />
            <TextField
              id="password"
              label="Password"
              onChange={(e) => setPassword(e.target.value)}
              className={classes.loginTextField}
              type="password"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={loginUser}
              className={classes.loginButton}
            >
              {text.login}
            </Button>
          </form>
        </div>
      </Card>
    </div>
  );
}
