import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useStyles } from "./styles";
import { text } from "./text";
import { VideoPanel } from "../../../Shared/VideoPanel";

export function CaseStudiesHome() {
  const classes = useStyles();

  const embedId = "8RaIUKsULbA";

  return (
    <div className={classes.container}>
      <h1 className={classes.headerText}>{text.header}</h1>
      <div className={classes.flexGrid}>
        <div className={classes.videoContainer}>
          <VideoPanel embedId={embedId} />
        </div>
        <div className={classes.contentArea}>
          <h3 className={classes.copyText}>{text.copy}</h3>
          <Link className={classes.link} to="/cases">
            <Button variant="contained" className={classes.button}>
              {text.button}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
