export const items = [
  {
    text: "Street Luminaries & High Masts",
    link: "/products?category=Street+Luminaires+%26+High+Masts",
    external: false,
  },
  {
    text: "Area Lighting",
    link: "/products?category=Area+Lighting",
    external: false,
  },
  {
    text: "Traffic Signals",
    link: "/products?category=Traffic+Signals",
    external: false,
  },
  {
    text: "Traffic Signs",
    link: "/products?category=Traffic+Signs",
    external: false,
  },
];

export const header = { text: "Products", link: "/products", external: false };
