import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  editDropDownFormControl: {
    width: "60%",
    margin: "10px auto auto auto",
  },
  editDropDown: {
    textAlign: "left",
  },
});
