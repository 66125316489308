import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  deleteButton: {
    width: "135px",
    height: "36px",
    margin: "10px auto 50px auto",
  },
  userPanel: {
    height: "20%",
    width: "40%",
    margin: "0 auto 0 auto",
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
  },
});
