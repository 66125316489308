import React, { useContext, useState } from "react";
import {
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";
import { myContext } from "Pages/Context";
import { logout } from "services";
import { useStyles } from "./styles";
import {
  menuItems,
  adminMenuItems,
  superAdminMenuItems,
  routes,
} from "../constants";

export function MenuMobile() {
  const classes = useStyles();
  const ctx = useContext(myContext);
  const history = useHistory();

  const [state, setState] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const handleRefresh = (route) => {
    if (
      route === routes.products &&
      window.location.pathname.includes(routes.products)
    ) {
      history.push(routes.products);
      window.location.reload();
    }
  };

  const handleAdminRoute = (route) => {
    if (route === routes.logout) {
      logout().then((res) => {
        if (res.data === "success") {
          window.location.href = routes.home;
        }
      });
    }
  };

  const publicMenuItems = menuItems.map((item) => {
    return (
      <Link
        key={item.label}
        to={item.route}
        className={classes.listItemText}
        onClick={() => {
          handleRefresh(item.route);
        }}
      >
        <ListItem button>
          <ListItemText primary={item.label} />
        </ListItem>
      </Link>
    );
  });

  const privateMenuItems = adminMenuItems.map((item) => {
    return (
      <Link
        key={item.label}
        to={item.route}
        className={classes.listItemText}
        onClick={() => {
          handleAdminRoute(item.route);
        }}
      >
        <ListItem button>
          <ListItemText primary={item.label} />
        </ListItem>
      </Link>
    );
  });

  const superPrivateMenuItems = superAdminMenuItems.map((item) => {
    return (
      <Link
        key={item.label}
        to={item.route}
        className={classes.listItemText}
        onClick={() => {
          handleAdminRoute(item.route);
        }}
      >
        <ListItem button>
          <ListItemText primary={item.label} />
        </ListItem>
      </Link>
    );
  });

  const list = (
    <List
      className={classes.list}
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      {publicMenuItems}
      {ctx ? <Divider /> : null}
      {ctx ? privateMenuItems : null}
      {ctx.isAdmin ? <Divider /> : null}
      {ctx.isAdmin ? superPrivateMenuItems : null}
    </List>
  );

  return (
    <div>
      <>
        <Menu
          className={classes.menuIcon}
          onClick={toggleDrawer(true)}
          fontSize="large"
        />
        <Drawer open={state} onClose={toggleDrawer(false)}>
          {list}
        </Drawer>
      </>
    </div>
  );
}
