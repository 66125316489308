import React from "react";
import { ProductNavigationPanel } from "../Shared";
import { CaseList } from "./components";
import { useStyles } from "./styles";

export function CaseStudyPage() {
  const classes = useStyles();
  return (
    <div className={classes.row}>
      <div className={`${classes.row} ${classes.hidden}`}>
        <ProductNavigationPanel />
      </div>
      <CaseList />
    </div>
  );
}
