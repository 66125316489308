import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  AddProductPanel,
  EditProductOrderPanel,
  ChooseEditProductPanel,
} from "./components";
import { createProduct, findAllProducts, findAllCategories } from "services";
import { useStyles } from "./styles";

export function ProductAdmin() {
  const classes = useStyles();
  const history = useHistory();

  const [productStatus, setProductStatus] = useState("");
  const [productName, setProductName] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [productSubcategory, setProductSubcategory] = useState("");
  const [productList, setProductList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [subCategoryList, setSubCategoryList] = useState([]);

  const addProduct = async (e) => {
    e.preventDefault();
    if (
      productName.replace(/\s/g, "") === "" ||
      productCategory === "" ||
      productSubcategory === ""
    ) {
      setProductStatus("Please Fill Out All Fields");
    } else {
      createProduct(productName, productCategory, productSubcategory).then(
        (res) => {
          if (res.data !== "Product Already Exists") {
            history.push(`/editproduct/${res.data}`);
            setProductStatus("Product Registered Successfully");
          } else {
            setProductStatus("Product Already Exists");
          }
        }
      );
    }
  };

  const handleDropDown = (event) => {
    setSelectedProduct(event.target.value);
  };

  useEffect(() => {
    findAllProducts().then((res) => {
      setProductList(res.data);
    });
    findAllCategories().then((res) => {
      setCategoryList(res.data);
    });
  }, []);

  useEffect(() => {
    if (categoryList.length > 0) {
      const res = categoryList.filter(
        (category) => category.productCategory === productCategory
      );
      if (res.length > 0) {
        setSubCategoryList(res[0].productSubCategories);
      }
    }
  }, [categoryList, productCategory]);

  useEffect(() => {
    setProductSubcategory("");
  }, [productCategory]);

  return (
    <div className={classes.row}>
      <AddProductPanel
        setProductName={setProductName}
        setProductCategory={setProductCategory}
        addProduct={addProduct}
        productCategory={productCategory}
        productStatus={productStatus}
        categoryList={categoryList}
        productSubcategory={productSubcategory}
        setProductSubcategory={setProductSubcategory}
        subCategoryList={subCategoryList}
        productName={productName}
      />
      <ChooseEditProductPanel
        productList={productList}
        selectedProduct={selectedProduct}
        handleDropDown={handleDropDown}
      />
      <EditProductOrderPanel />
    </div>
  );
}
