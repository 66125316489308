import React, { useContext } from "react";
import { makeStyles, createTheme, ThemeProvider } from "@material-ui/core";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { myContext } from "Pages/Context";
import {
  About,
  HomePage,
  NotFound,
  Careers,
  Contact,
  Login,
  AdminPage,
  Profile,
  ProductPage,
  BannerAdmin,
  ProductAdmin,
  EditProductPage,
  Footer,
  ProductOrder,
  NavBar,
  CaseStudyPage,
} from "./Pages";
import "./main.css";
import dotenv from "dotenv";
import { ScrollToTop } from "ScrollToTop";
dotenv.config();

const theme = createTheme();

theme.typography.h6 = {
  fontWeight: "normal",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  fontSize: "1rem",
  "@media (max-width:650px)": {
    fontSize: "1rem",
  },
};

const useStyles = makeStyles({
  wrapper: {
    marginBottom: "235px",
    "@media (max-width: 650px)": {
      marginBottom: "570px",
    },
    "@media (max-width: 280px)": {
      marginBottom: "670px",
    },
  },
});

function App() {
  const ctx = useContext(myContext);
  const classes = useStyles();

  if (ctx === "G@o$Gjs$SbmQM5rOlCGysDTdrrPuxL") {
    return null;
  }

  return (
    <BrowserRouter>
      <ScrollToTop>
        <ThemeProvider theme={theme}>
          <>
            <NavBar />
            <div className={classes.wrapper}>
              <Switch>
                <Route path="/" exact component={HomePage}></Route>
                <Route path="/products" exact component={ProductPage}></Route>
                <Route path="/about" exact component={About}></Route>
                <Route path="/contact" exact component={Contact}></Route>
                <Route path="/careers" exact component={Careers}></Route>
                <Route path="/cases" exact component={CaseStudyPage}></Route>
                {ctx ? (
                  <Switch>
                    {ctx.isAdmin ? (
                      <Route path="/admin" exact component={AdminPage}></Route>
                    ) : null}
                    <Route path="/profile" exact component={Profile}></Route>
                    <Route
                      path="/productadmin"
                      exact
                      component={ProductAdmin}
                    ></Route>
                    <Route
                      path="/banneradmin"
                      exact
                      component={BannerAdmin}
                    ></Route>
                    <Route
                      path="/productorder"
                      exact
                      component={ProductOrder}
                    ></Route>
                    <Route
                      path="/editproduct/:id"
                      exact
                      component={EditProductPage}
                    ></Route>
                    <Route path="*" component={NotFound}></Route>
                  </Switch>
                ) : (
                  <Switch>
                    <Route path="/login" exact component={Login}></Route>
                    <Route path="*" component={NotFound}></Route>
                  </Switch>
                )}
                <Route path="*" component={NotFound}></Route>
              </Switch>
            </div>
            <Footer />
          </>
        </ThemeProvider>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
