import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  card: {
    height: "500px",
    width: "500px",
    margin: "50px auto 50px auto",
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
    "@media (min-width: 1900px)": {
      width: "700px",
      height: "800px",
    },
    "@media (max-width: 550px)": {
      width: "400px",
      height: "500px",
    },
    "@media (max-width: 450px)": {
      width: "300px",
      height: "500px",
    },
    "@media (max-width: 325px)": {
      width: "250px",
      height: "500px",
    },
  },
  column: {
    display: "flex",
    flexDirection: "column",
    margin: "auto auto auto auto",
    alignItems: "center",
  },
  submitButton: {
    width: "30%",
    margin: "10px auto auto auto",
  },
  editTextField: {
    width: "60%",
    margin: "10px auto auto auto",
  },
  phoneTextField: {
    width: "60%",
    margin: "10px auto auto auto!important",
  },
  margin: {
    display: "inline-block",
    marginTop: "5px",
    width: "90%",
    textAlign: "left",
  },
  list: {
    listStyleType: "none",
    display: "inline-block",
    textAlign: "left",
  },
  bullet: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  bulletText: {
    marginLeft: "5px",
    "@media (min-width: 1900px)": {
      fontSize: "1.5rem",
    },
  },
  blank: {
    height: "24px",
    width: "24px",
  },
  header: {
    margin: "5px 0px",
  },
});
