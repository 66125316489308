import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  listItemText: {
    textDecoration: "none",
    color: "black",
  },
  menuIcon: {
    cursor: "pointer",
    fill: "black",
    marginLeft: "5px",
    "@media (max-width: 500px)": {
      transform: "scale(.75)",
    },
  },
  list: {
    width: "250px",
  },
});
