import React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { columns } from "./constants";
import { CustomLoadingOverlay } from "./components";
import { useStyles } from "./styles";

export function ImagePanelList({
  handleOrderImageChange,
  productLinks,
  selectedProduct,
  imageUploadWait,
}) {
  const classes = useStyles();

  const rows1 = productLinks.map((product) => {
    return {
      id: product.productUrl,
      name: product.productName,
      order: product.productOrder,
    };
  });

  return (
    <div className={classes.container}>
      <DataGrid
        components={{
          LoadingOverlay: CustomLoadingOverlay,
        }}
        loading={imageUploadWait}
        rows={rows1}
        columns={columns}
        pageSize={6}
        onCellEditCommit={handleOrderImageChange}
        onSelectionModelChange={(newSelection) => {
          selectedProduct(newSelection);
        }}
      />
    </div>
  );
}
