import React from "react";
import { CareerCard } from "./components";
import { useStyles } from "./styles";
import { text } from "./text";

export function CareerSection() {
  const classes = useStyles();

  return (
    <div className={classes.column}>
      <h1 className={classes.title}>{text.header}</h1>
      <h3 className={classes.description}>{text.description}</h3>
      <CareerCard />
    </div>
  );
}
