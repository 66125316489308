export const text = {
  header: "Banner Image Upload",
  subheader: "For best results please follow the below criteria:",
  uploading: "Uploading...",
  wait: "Please Wait",
  uploaded: "Banner Image Uploaded Successfully.",
  changeImage: "Change Image",
  status: {
    error: "error",
    success: "success",
  },
  criteria: {
    format: "File must be in .png or .jpg format",
    width: "Width must be at least 3000px",
    height: "Height must be at least 1500px",
    ratio: "Width divided by height must be between 2.45 and 2.55",
  },
};
