import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  videoContainer: {
    position: "relative",
    paddingTop: "56%",
  },
  video: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
  },
});
