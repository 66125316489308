import React, { useContext } from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import { myContext } from "Pages/Context";
import { logout } from "services";
import { useStyles } from "./styles";
import {
  adminMenuItems,
  superAdminMenuItems,
  routes,
} from "../../../constants";
import { adminLabel } from "./constants";

export function AdminDropDown() {
  const classes = useStyles();

  const ctx = useContext(myContext);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAdminRoute = (route) => {
    if (route === routes.logout) {
      logout().then((res) => {
        if (res.data === "success") {
          window.location.href = routes.home;
        }
      });
    }
  };

  const privateMenuItems = adminMenuItems.map((item) => {
    return (
      <MenuItem
        key={item.label}
        onClick={() => {
          handleAdminRoute(item.route);
        }}
        component={Link}
        to={item.route}
      >
        {item.label}
      </MenuItem>
    );
  });

  const superPrivateMenuItems = superAdminMenuItems.map((item) => {
    return (
      <MenuItem
        key={item.label}
        onClick={() => {
          handleAdminRoute(item.route);
        }}
        component={Link}
        to={item.route}
      >
        {item.label}
      </MenuItem>
    );
  });

  return (
    <>
      <Button
        className={classes.button}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClose={handleClose}
        onClick={handleClick}
      >
        {adminLabel}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
        disableScrollLock={true}
      >
        {privateMenuItems}
        {ctx.isAdmin ? superPrivateMenuItems : null}
      </Menu>
    </>
  );
}
