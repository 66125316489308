import React, { createContext, useEffect, useState } from "react";
import { userContext } from "services";

export const myContext = createContext();

export default function Context(props) {
  const [user, setUser] = useState("G@o$Gjs$SbmQM5rOlCGysDTdrrPuxL");

  useEffect(() => {
    userContext().then((res) => {
      setUser(res.data);
    });
  }, []);

  return <myContext.Provider value={user}>{props.children}</myContext.Provider>;
}
