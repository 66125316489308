import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  toggle: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
  },
  temp: {
    marginTop: "auto",
    marginBottom: "auto",
  },
});
