import React from "react";
import { Button, Card, TextField } from "@material-ui/core";
import { CategoryDropDown, SubCategoryDropDown } from "./components";
import { useStyles } from "./styles";
import { text } from "./text";

export function AddProductPanel({
  productName,
  setProductName,
  setProductCategory,
  addProduct,
  productCategory,
  productStatus,
  categoryList,
  productSubcategory,
  setProductSubcategory,
  subCategoryList,
}) {
  const classes = useStyles();

  return (
    <div className={classes.column}>
      <Card className={classes.card}>
        <h1>{text.product}</h1>
        <form className={classes.column} noValidate autoComplete="off">
          <TextField
            id="Product Name"
            label="Product Name"
            onChange={(e) => setProductName(e.target.value)}
            className={classes.editTextField}
          />
          <CategoryDropDown
            productCategory={productCategory}
            setProductCategory={setProductCategory}
            categoryList={categoryList}
          />
          <SubCategoryDropDown
            productSubcategory={productSubcategory}
            setProductSubcategory={setProductSubcategory}
            subCategoryList={subCategoryList}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => addProduct(e)}
            disabled={
              productName.replace(/\s/g, "") === "" ||
              productCategory === "" ||
              productSubcategory === ""
                ? true
                : false
            }
            className={classes.submitButton}
          >
            {text.submit}
          </Button>
          <h5>{productStatus}</h5>
        </form>
      </Card>
    </div>
  );
}
