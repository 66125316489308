import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  row: {
    display: "flex",
    flexDirection: "row",
    margin: "auto",
    flexWrap: "wrap",
  },
});
