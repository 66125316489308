import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Thumbs, Keyboard } from "swiper";
import { ButtonBase } from "@material-ui/core";
import { links } from "./constants";
import "swiper/swiper-bundle.min.css";
import "./multiimagestyles.css";

SwiperCore.use([Navigation, Thumbs, Keyboard]);

export function ProductMultiImageView({ productLinks, productVideoId = [] }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const productImagesThumbs = productLinks.map((image) => (
    <SwiperSlide key={`thumb_${image._id}`} className="swiperSlide">
      <img alt={image.productName} src={image.productUrl} />
    </SwiperSlide>
  ));

  const productImages = productLinks.map((image) => (
    <SwiperSlide key={`image_${image._id}`} className="swiperSlide">
      <ButtonBase
        onClick={() => {
          window.open(image.productUrl, "_blank").focus();
        }}
      >
        <img alt={image.productName} src={image.productUrl} />
      </ButtonBase>
    </SwiperSlide>
  ));

  const validVideoArray = productVideoId.filter((id) => id.length === 11);

  if (validVideoArray.length > 0) {
    const productVideoThumbs = validVideoArray.map((videoId, index) => (
      <SwiperSlide key={`thumb_${videoId}`} className="swiperSlide">
        <img alt={`Video_${index}`} src={links.defaultThumb} />
      </SwiperSlide>
    ));

    productVideoThumbs.forEach((videoThumb) =>
      productImagesThumbs.push(videoThumb)
    );

    const videoSize = window.innerWidth <= 350 ? 150 : 350;

    const productVideos = validVideoArray.map((videoId) => (
      <SwiperSlide key={`thumb_${videoId}`} className="swiperSlide">
        <iframe
          width={videoSize}
          height={videoSize}
          src={`https://www.youtube-nocookie.com/embed/${videoId}?rel=0&loop=1&showinfo=0`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded YouTube"
        />
      </SwiperSlide>
    ));

    productVideos.forEach((video) => productImages.push(video));
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      {productImages.length > 1 ? (
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={5}
          allowTouchMove={false}
          centeredSlidesBounds={true}
          slidesPerView={8}
          freeMode={true}
          watchSlidesProgress={true}
          className="mySwiper"
          direction="vertical"
          autoHeight={true}
          mousewheel={true}
          style={{ margin: "0 1%" }}
        >
          {productImagesThumbs}
        </Swiper>
      ) : null}
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        loop={true}
        thumbs={{ swiper: thumbsSwiper }}
        className="mySwiper2"
        spaceBetween={5}
      >
        {productImages}
      </Swiper>
    </div>
  );
}
