import axios from "axios";

export const editBannerById = (id, bannerImage) => {
  return axios.post(
    `${process.env.REACT_APP_URL}/banneredit`,
    {
      id,
      bannerImage,
    },
    {
      withCredentials: true,
    }
  );
};

export const findAllBanners = () => {
  return axios.get(`${process.env.REACT_APP_URL}/getallbanners`, {
    withCredentials: true,
  });
};
