import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  row: {
    display: "flex",
    flexDirection: "row",
    margin: "auto",
    flexWrap: "wrap",
    alignItems: "center",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    flexWrap: "wrap",
    alignItems: "center",
  },
  img: {
    margin: "12.5px 0px 12.5px 25px",
  },
  button: {
    width: "175px",
    height: "30px",
    alignItems: "center",
    marginLeft: "10px",
  },
  input: {
    display: "none",
  },
  card: {
    width: "600px",
    height: "600px",
    justifyItems: "center",
    alignItems: "center",
    margin: "10px 30px 10px 30px",
  },
  cardContent: {
    margin: "auto",
    width: "70%",
  },
  header: {
    textAlign: "center",
    margin: "15px",
  },
  subHeader: {
    marginBottom: "10px",
  },
  progress: {
    marginTop: "75px",
  },
});
