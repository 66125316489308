import React from "react";
import TreeView from "@material-ui/lab/TreeView";
import {
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
} from "@material-ui/icons";
import { TreeItemContainer } from "./components";
import { useStyles } from "./styles";

export function ProductNavigationPanel({
  categoriesArray,
  productArray,
  expanded,
  handleToggle,
  handleSelect,
  handleIconClick,
  handleLabelClick,
}) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
      >
        <TreeItemContainer
          categoriesArray={categoriesArray}
          productArray={productArray}
          handleIconClick={handleIconClick}
          handleLabelClick={handleLabelClick}
        />
      </TreeView>
    </div>
  );
}
