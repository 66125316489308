import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    minHeight: "400px",
  },
  description: {
    whiteSpace: "pre-line",
    overflowWrap: "break-word",
  },
}));
