import React from "react";
import { Card } from "@material-ui/core";
import {
  LocalPhone as LocalPhoneIcon,
  Print as PrintIcon,
  Email as EmailIcon,
  LocationOn as LocationOnIcon,
} from "@material-ui/icons";
import { useStyles } from "./styles";
import { iframe } from "./constants";
import { text } from "./text";

export function ContactInfo() {
  const classes = useStyles();

  return (
    <div className={classes.column}>
      <Card className={classes.card}>
        <h1 className={classes.header}>Contact</h1>
        <iframe
          title="map"
          src={iframe.url}
          width="90%"
          height="60%"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen
          aria-hidden="false"
          tabIndex="0"
        />
        <div className={classes.margin}>
          <ul className={classes.list}>
            <li className={classes.bullet}>
              <LocationOnIcon />
              <span className={classes.bulletText}>{text.street}</span>
            </li>
            <li className={classes.bullet}>
              <div className={classes.blank}></div>
              <span className={classes.bulletText}>{text.city}</span>
            </li>
            <li className={classes.bullet}>
              <LocalPhoneIcon />
              <span className={classes.bulletText}>{text.phone}</span>
            </li>
            <li className={classes.bullet}>
              <PrintIcon />
              <span className={classes.bulletText}>{text.fax}</span>
            </li>
            <li className={classes.bullet}>
              <EmailIcon />
              <span className={classes.bulletText}>{text.email}</span>
            </li>
          </ul>
        </div>
      </Card>
    </div>
  );
}
