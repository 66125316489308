export const items = [
  { text: "About", link: "/About", external: false },
  { text: "Case Studies", link: "/Cases", external: false },
  { text: "Contact", link: "/Contact", external: false },
  { text: "Careers", link: "/Careers", external: false },
  {
    text: "LinkedIn",
    link: "https://www.linkedin.com/company/trastar-inc/",
    external: true,
  },
];

export const header = { text: "Information", link: null, external: false };
