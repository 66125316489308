import { sha256 } from "js-sha256";

export const uploadProductImage = async (file) => {
  const data = new FormData();

  const preset = process.env.REACT_APP_CLOUD_PRESET_IMAGES;

  data.append("file", file);
  data.append("upload_preset", preset);
  data.append("api_key", process.env.REACT_APP_CLOUD_KEY);
  data.append("timestamp", Date.now());
  data.append("use_filename", "true");

  const x = `timestamp=${Date.now()}&upload_preset=${preset}&use_filename=true${
    process.env.REACT_APP_CLOUD_SECRET
  }`;

  const z = sha256(x);

  data.append("signature", z);

  return fetch("https://api.cloudinary.com/v1_1/df3ajcohw/image/upload", {
    method: "POST",
    body: data,
  }).then((res) => {
    return res.json();
  });
};

export const uploadProductAsset = async (file) => {
  const data = new FormData();

  const preset = process.env.REACT_APP_CLOUD_PRESET_ASSETS;

  data.append("file", file);
  data.append("upload_preset", preset);
  data.append("api_key", process.env.REACT_APP_CLOUD_KEY);
  data.append("timestamp", Date.now());
  data.append("use_filename", "true");

  const x = `timestamp=${Date.now()}&upload_preset=${preset}&use_filename=true${
    process.env.REACT_APP_CLOUD_SECRET
  }`;

  const z = sha256(x);

  data.append("signature", z);

  return fetch("https://api.cloudinary.com/v1_1/df3ajcohw/raw/upload", {
    method: "POST",
    body: data,
  }).then((res) => {
    return res.json();
  });
};

export const uploadBannerImage = async (file) => {
  const data = new FormData();

  const preset = process.env.REACT_APP_CLOUD_PRESET_BANNER;

  data.append("file", file);
  data.append("upload_preset", preset);
  data.append("api_key", process.env.REACT_APP_CLOUD_KEY);
  data.append("timestamp", Date.now());
  data.append("use_filename", "true");

  const x = `timestamp=${Date.now()}&upload_preset=${preset}&use_filename=true${
    process.env.REACT_APP_CLOUD_SECRET
  }`;

  const z = sha256(x);

  data.append("signature", z);

  return fetch("https://api.cloudinary.com/v1_1/df3ajcohw/image/upload", {
    method: "POST",
    body: data,
  }).then((res) => {
    return res.json();
  });
};
