import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  row: {
    display: "flex",
    flexDirection: "row",
    "@media (max-width: 915px)": {
      flexWrap: "wrap",
    },
  },
  column: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
  },
  hidden: {
    "@media (max-width: 915px)": {
      display: "none",
    },
  },
  contactBlocks: {
    display: "flex",
    flexDirection: "row",
    gap: "50px",
    justifyContent: "center",
    "@media (min-width:1900px)": {
      flexDirection: "row",
      gap: "100px",
    },
    "@media (max-width:1500px)": {
      flexDirection: "column",
      flexWrap: "wrap",
      gap: "0px",
      justifyItems: "center",
    },
    "@media (max-width: 915px)": {
      flexDirection: "row",
    },
  },
});
