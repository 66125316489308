import React from "react";
import Grid from "@material-ui/core/Grid";
import { ProductCard } from "./components";
import { useStyles } from "./styles";

export function ProductList({ productArray, setProductId }) {
  const classes = useStyles();

  const productElements = productArray.map((card) => {
    if (card.isActive) {
      return (
        <Grid key={card.id} item>
          <ProductCard setProductId={setProductId} card={card} />
        </Grid>
      );
    } else {
      return null;
    }
  });

  return (
    <div>
      <Grid className={classes.grid} container spacing={3}>
        {productElements}
      </Grid>
    </div>
  );
}
