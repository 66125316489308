import React from "react";
import { Link } from "react-router-dom";
import { useStyles } from "./styles";

export function ListHeader({ header }) {
  const classes = useStyles();

  const { text, link } = header;

  const headerMarkup = (
    <li>
      <h2 className={classes.headerText}>{text}</h2>
    </li>
  );

  if (link) {
    return (
      <Link className={classes.link} to={link}>
        {headerMarkup}
      </Link>
    );
  }

  return headerMarkup;
}
