import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  card: {
    width: "100%",
    maxWidth: "1200x",
    marginBottom: "2rem",
  },
  button: {
    color: "white",
    backgroundColor: "#110884",
    marginTop: "1rem",
    "&:hover": {
      backgroundColor: "#110884",
    },
  },
  cardContent: {
    display: "flex",
    justifyContent: "space-between",
  },
  header: {
    fontSize: "2rem",
  },
  videoContainer: {
    width: "100%",
    height: "100%",
  },
  body: {
    fontSize: "1.2rem",
  },
  link: {
    textDecoration: "none",
  },
});
