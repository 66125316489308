import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  row: {
    display: "flex",
    flexDirection: "row",
    margin: "auto",
    flexWrap: "wrap",
    marginBottom: "50px",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    flexWrap: "wrap",
  },
});
