export const header = "FEATURED PRODUCTS";

export const products = [
  {
    label: "High Mast",
    image:
      "https://res.cloudinary.com/df3ajcohw/image/upload/f_auto,q_auto/assets/yqtnidp1botboggeyheb.jpg",
    link: "/products?subcategory=High+Mast+JXM-ST-HM+Series",
  },
  {
    label: "Cobra Heads",
    image:
      "https://res.cloudinary.com/df3ajcohw/image/upload/f_auto,q_auto/assets/tqfhrbsjms10swmbygdo.jpg",
    link: "/products?subcategory=Cobra+Head+DURA+Series",
  },
  {
    label: "Street Signs",
    image:
      "https://res.cloudinary.com/df3ajcohw/image/upload/f_auto,q_auto/assets/kj0d7blinl50enb4uwno.jpg",
    link: "/products?subcategory=Internally+Illuminated+Street+Name+Signs",
  },
  {
    label: "Traffic Signals",
    image:
      "https://res.cloudinary.com/df3ajcohw/image/upload/f_auto,q_auto/assets/llk5o5ihzopcyv64gpmz.jpg",
    link: "/products?category=Traffic+Signals",
  },
];
