import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  categoryItem: {
    fontWeight: 700,
  },
  allProductsText: {
    fontSize: "25px",
  },
});
