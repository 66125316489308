import React from "react";
import { useStyles } from "./styles";
import { items, header } from "./text";
import { ListHeader } from "../ListHeader";
import { ListItems } from "../ListItems";

export function ProductsList() {
  const classes = useStyles();

  return (
    <div className={classes.row}>
      <div className={classes.column}>
        <ul className={classes.list}>
          <ListHeader header={header} />
          <hr className={classes.line} />
          <ListItems items={items} />
        </ul>
      </div>
    </div>
  );
}
