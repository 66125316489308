import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  videoResponsive: {
    overflow: "hidden",
    paddingBottom: "56%",
    position: "relative",
    height: "0",
  },
  iframe: {
    left: "0",
    top: "0",
    height: "100%",
    width: "100%",
    position: "absolute",
    maxHeight: "350px",
    maxWidth: "560px",
  },
});
