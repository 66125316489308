import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  headerImg: {
    maxWidth: "75%",
    "@media (max-width: 1199px)": {
      display: "block",
      margin: "0 auto 0 auto",
    },
  },
  container: {
    "@media (max-width: 1199px)": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
    },
  },
  appBar: {
    maxWidth: "100%",
    position: "sticky",
    display: "flex",
    flexDirection: "row",
    backgroundColor: "white",
    alignItems: "center",
    marginBottom: "20px",
  },
  menuFull: {
    "@media (max-width: 1200px)": {
      display: "none",
    },
  },
  menuSmall: {
    "@media (min-width: 1199px)": {
      display: "none",
    },
  },
});
