import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  flexGrid: {
    display: "flex",
    alignContent: "space-around",
    justifyContent: "space-around",
    "@media (max-width: 650px)": {
      flexWrap: "wrap",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
    },
  },
  componentContainer: {
    height: "210px",
    "@media (max-width: 650px)": {
      height: "550px",
    },
    "@media (max-width: 280px)": {
      height: "560px",
    },
  },
});
