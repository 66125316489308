import React, { useContext, useEffect, useState } from "react";
import { myContext } from "Pages/Context";
import { Register, UserPanel } from "./components";
import { IconButton, Snackbar } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { allUsers, create, remove } from "services";
import { useStyles } from "./styles";

export function AdminPage() {
  const classes = useStyles();

  const ctx = useContext(myContext);

  const [userData, setUserData] = useState([]);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isRegistrationDisabled, setIsRegistrationDisabled] = useState(true);
  const [gridSelection, setGridSelection] = useState({});
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");

  const deleteUser = (id) => {
    remove(id)
      .then((res) => {
        setLoading(true);
        if (res.data === "success") {
          setSnackBarMessage("User Deleted Successfully");
        } else {
          setSnackBarMessage("Failure To Delete User");
        }
      })
      .then(() => {
        setOpen(true);
        setLoading(false);
        setGridSelection({});
      });
  };

  const selectedUser = (row) => {
    setGridSelection(row);
  };

  const registerUser = () => {
    create(username, password)
      .then((res) => {
        setLoading(true);
        if (res.data === "success") {
          setSnackBarMessage("User Registered Successfully");
        } else if (res.data === "Improper Values") {
          setSnackBarMessage("Username or Password Requirements Not Met");
        } else {
          setSnackBarMessage("Failure To Register User");
        }
      })
      .then(() => {
        setOpen(true);
        setLoading(false);
        setUsername("");
        setPassword("");
      });
  };

  const validateRegistrationForm = () => {
    if (username.length <= 5 || username.length >= 15 || password.length < 8) {
      setIsRegistrationDisabled(true);
    } else {
      setIsRegistrationDisabled(false);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    allUsers().then((res) => {
      setUserData(
        res.data.filter((item) => {
          return item.username !== ctx.username;
        })
      );
    });
  }, [loading, ctx.username]);

  return (
    <div>
      <div className={classes.column}>
        <Register
          setUsername={setUsername}
          setPassword={setPassword}
          username={username}
          password={password}
          registerUser={registerUser}
          validateRegistrationForm={validateRegistrationForm}
          isRegistrationDisabled={isRegistrationDisabled}
        />
        <UserPanel
          userArray={userData}
          deleteUser={deleteUser}
          selectedUser={selectedUser}
          gridSelection={gridSelection}
        />
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={snackBarMessage}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </div>
  );
}
