import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  card: {
    height: 250,
    width: "99%",
    margin: "10px 0px 10px 0px",
    textAlign: "center",
    border: "1px solid black",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    justifyContent: "left",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    margin: "auto",
  },
  editTextField: {
    width: "50%",
    margin: "10px",
  },
  margin: {
    display: "inline-block",
    width: "90%",
    height: "80%",
  },
  editOpenTextField: {
    width: "100%",
    margin: "10px",
    alignSelf: "left",
  },
});
