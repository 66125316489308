import React from "react";
import { Button, Card } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useStyles } from "./styles";
import { text } from "./text";

export function EditProductOrderPanel() {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <div className={classes.margin}>
        <Button
          variant="contained"
          color="primary"
          className={classes.orderButton}
        >
          <Link className={classes.link} to={"/productorder/"}>
            {text.order}
          </Link>
        </Button>
      </div>
    </Card>
  );
}
