import React from "react";
import { GridOverlay } from "@material-ui/data-grid";
import { LinearProgress } from "@material-ui/core";
import { useStyles } from "./styles";

export function CustomLoadingOverlay() {
  const classes = useStyles();

  return (
    <GridOverlay>
      <div className={classes.container}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}
