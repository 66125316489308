import React, { useContext } from "react";
import { Button, Toolbar } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { myContext } from "Pages/Context";
import { AdminDropDown } from "./components";
import { useStyles } from "./styles";
import { menuItems } from "../constants";

export function MenuFull() {
  const classes = useStyles();

  const ctx = useContext(myContext);
  const history = useHistory();

  const productRoute = "/products";

  const handleRefresh = (route) => {
    if (
      route === productRoute &&
      window.location.pathname.includes(productRoute)
    ) {
      history.push(productRoute);
      window.location.reload();
    }
  };

  const publicMenuItems = menuItems.map((item) => {
    return (
      <Button aria-controls="simple-menu" aria-haspopup="true" key={item.label}>
        <Link
          className={classes.button}
          to={item.route}
          onClick={() => {
            handleRefresh(item.route);
          }}
        >
          {item.label}
        </Link>
      </Button>
    );
  });

  const privateMenuItems = ctx ? <AdminDropDown /> : null;

  return (
    <Toolbar className={classes.toolbar}>
      {publicMenuItems}
      {privateMenuItems}
    </Toolbar>
  );
}
