import { makeStyles } from "@material-ui/core";

const isNotFirefox = !navigator.userAgent.match(/firefox|fxios/i)
  ? true
  : false;

const position = isNotFirefox ? "absolute" : "static";

export const useStyles = makeStyles({
  footer: {
    position: position,
    bottom: 0,
    right: 0,
    left: 0,
    width: "100%",
    backgroundColor: "#DADAE1",
  },
});
