import React from "react";
import { Button, ClickAwayListener } from "@material-ui/core";
import { ImagePanelList } from "./components";
import { useStyles } from "./styles";
import { text } from "./text";

export function ImagePanel({
  handleClickAwayGrid,
  productLinks,
  selectedProduct,
  gridSelection,
  deleteImage,
  handleOrderImageChange,
  imageUploadWait,
}) {
  const classes = useStyles();

  return (
    <ClickAwayListener onClickAway={handleClickAwayGrid}>
      <div className={classes.userPanel}>
        <ImagePanelList
          handleOrderImageChange={handleOrderImageChange}
          productLinks={productLinks}
          selectedProduct={selectedProduct}
          imageUploadWait={imageUploadWait}
        />
        <Button
          className={classes.deleteButton}
          variant="contained"
          color="secondary"
          onClick={() => {
            deleteImage(gridSelection);
          }}
          disabled={Object.keys(gridSelection).length === 0 ? true : false}
        >
          {text.delete}
        </Button>
      </div>
    </ClickAwayListener>
  );
}
