import React from "react";
import { CardContent, Card, Typography, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useStyles } from "./styles";
import { VideoPanel } from "../../../../../Shared/VideoPanel";
export function CaseCard({ video }) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <div className={classes.videoContainer}>
        <VideoPanel embedId={video.id} autoplay={false} />
      </div>
      <CardContent className={classes.justifyContent}>
        <Typography gutterBottom className={classes.header}>
          {video.header}
        </Typography>
        <Typography className={classes.body} gutterBottom variant="body1">
          {video.body}
        </Typography>
        <Link className={classes.link} to={video.link}>
          <Button variant="contained" className={classes.button}>
            Go to Product
          </Button>
        </Link>
      </CardContent>
    </Card>
  );
}
