import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  row: {
    display: "flex",
    flexDirection: "row",
    "@media (max-width: 915px)": {
      flexWrap: "wrap",
    },
  },
  hidden: {
    "@media (max-width: 915px)": {
      display: "none",
    },
  },
});
