import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  column: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
  },
  image: {
    height: "250px",
    width: "250px",
    margin: "auto",
  },
  input: {
    display: "none",
  },
  uploadButton: {
    margin: "10px 0px 10px 0px",
  },
  card: {
    height: 600,
    width: 500,
    margin: "5px 10px 5px 10px",
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
    border: "1px solid black",
  },
  progressDiv: {
    display: "flex",
    height: 50,
    width: 50,
    margin: "auto",
    justifyContent: "center",
    alignContent: "center",
  },
  progress: {
    margin: "auto",
    display: "block",
  },
});
