import React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { useStyles } from "./styles";
import { columns } from "./constants";

export function UserPanelList({ userArray, selectedUser }) {
  const classes = useStyles();

  const rows1 = userArray.map((user) => {
    return { id: user.id, username: user.username, admin: user.isAdmin };
  });

  return (
    <div className={classes.container}>
      <DataGrid
        rows={rows1}
        columns={columns}
        pageSize={5}
        onSelectionModelChange={(newSelection) => {
          selectedUser(newSelection);
        }}
      />
    </div>
  );
}
