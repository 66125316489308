import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  tableCell: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    paddingRight: "5px",
  },
});
