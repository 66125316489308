import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useStyles } from "./styles";
import { text } from "./text";

export function AboutUsHome() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <h1 className={classes.headerText}>{text.header}</h1>
      <h3 className={classes.copyText}>{text.copy}</h3>
      <Link className={classes.link} to="/about">
        <Button variant="contained" className={classes.button}>
          {text.button}
        </Button>
      </Link>
    </div>
  );
}
