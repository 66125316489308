import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  bullet: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "2px",
  },
  blank: {
    height: "24px",
    width: "0px",
  },
  link: {
    textDecoration: "none",
    color: "black",
    "&:visited": {
      color: "black",
    },
  },
});
