import React from "react";
import { Switch, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { useStyles } from "./styles";
import { text } from "./text";

const CustomSwitch = withStyles({
  colorPrimary: {
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#457b9d",
      borderColor: "#457b9d",
    },
  },
  track: {
    backgroundColor: "#457b9d",
  },
  thumb: {
    backgroundColor: "#457b9d",
  },
})(Switch);

export function ActiveToggle({ isActive, handleActiveSwitch }) {
  const classes = useStyles();
  return (
    <div className={classes.toggle}>
      <span className={classes.temp}>{text.disabled}</span>
      <CustomSwitch
        className={classes.toggle}
        onClick={handleActiveSwitch}
        checked={isActive}
        name="checkedB"
        color="primary"
        data-testid="toggle"
      />
      <span className={classes.temp}>{text.active}</span>
    </div>
  );
}

ActiveToggle.propTypes = {
  isActive: PropTypes.bool.isRequired,
  handleActiveSwitch: PropTypes.func.isRequired,
};
