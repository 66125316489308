import axios from "axios";

export const logout = () => {
  return axios.get(`${process.env.REACT_APP_URL}/logout`, {
    withCredentials: true,
  });
};

export const login = (username, password) => {
  return axios.post(
    `${process.env.REACT_APP_URL}/login`,
    {
      username,
      password,
    },
    {
      withCredentials: true,
    }
  );
};

export const create = (username, password) => {
  return axios.post(
    `${process.env.REACT_APP_URL}/register`,
    {
      username,
      password,
    },
    {
      withCredentials: true,
    }
  );
};

export const remove = (id) => {
  return axios.post(
    `${process.env.REACT_APP_URL}/deleteuser`,
    {
      id,
    },
    {
      withCredentials: true,
    }
  );
};

export const userContext = () => {
  return axios.get(`${process.env.REACT_APP_URL}/user`, {
    withCredentials: true,
  });
};

export const allUsers = () => {
  return axios.get(`${process.env.REACT_APP_URL}/getallusers`, {
    withCredentials: true,
  });
};
