import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  column: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    margin: "auto",
  },
  text: {
    margin: "auto",
  },
  card: {
    height: 500,
    width: 500,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    margin: "5px 10px 5px 10px",
    border: "1px solid black",
  },
  submitButton: {
    width: "100%",
    margin: "10px auto auto auto",
  },
  syncIndicator: {
    display: "flex",
    flexDirection: "row",
    margin: "auto",
    width: "230px",
  },
});
