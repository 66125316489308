import React from "react";
import { Link } from "react-router-dom";
import { useStyles } from "./styles";
import { header, products } from "./constants";

export function FeaturedProducts() {
  const classes = useStyles();

  const featuredProducts = products.map((product) => {
    return (
      <Link key={product.label} to={product.link}>
        <div className={classes.imageContainer}>
          <img
            className={classes.featureImage}
            alt={product.label}
            src={product.image}
          />
          <div className={classes.middle}>
            <div className={classes.text}>{product.label}</div>
          </div>
        </div>
      </Link>
    );
  });

  return (
    <div className={classes.componentContainer}>
      <h1 className={classes.featuredHeader}>{header}</h1>
      <div className={classes.flexGrid}>{featuredProducts}</div>
    </div>
  );
}
