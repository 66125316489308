import React from "react";
import { AboutUsCard } from "./components";
import { ProductNavigationPanel } from "../Shared";
import { useStyles } from "./styles";

export function About() {
  const classes = useStyles();

  return (
    <div className={classes.row}>
      <div className={`${classes.row} ${classes.hidden}`}>
        <ProductNavigationPanel />
      </div>
      <AboutUsCard />
    </div>
  );
}
