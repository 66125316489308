import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: "10px",
    paddingBottom: "170px",
  },
  image: {
    width: 400,
    height: 400,
    "@media (max-width: 550px)": {
      width: 300,
      height: 300,
    },
    "@media (max-width: 350px)": {
      width: 200,
      height: 200,
    },
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    width: "400px",
    height: "400px",
    "@media (max-width: 550px)": {
      width: 200,
      height: 200,
    },
    "@media (max-width: 350px)": {
      width: 200,
      height: 200,
    },
  },
  description: {
    whiteSpace: "pre-line",
    overflowWrap: "break-word",
  },
}));
