import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  deleteButton: {
    width: "190px",
    height: "36px",
    margin: "10px auto 50px auto",
  },
  userPanel: {
    height: 200,
    width: 400,
    margin: "auto",
    textAlign: "center",
  },
});
