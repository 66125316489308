import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { CardActionArea, CardContent, Card } from "@material-ui/core";
import { useStyles } from "./styles";
import { Skeleton } from "@mui/material";

export function ProductCard({ card, setProductId }) {
  const classes = useStyles();
  let history = useHistory();
  let url = new URL(`${window.location.origin}/products/`);
  let search = window.location.search;
  let params = new URLSearchParams(search);

  const handleRedirect = () => {
    setProductId(card.id);
    params.set("id", card.id);
    url.search = params.toString();
    history.push(url.search);
  };

  const [loading, setLoading] = useState(true);

  const imageLoaded = () => {
    if (loading) {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className={classes.skeletonContainer}>
          <Skeleton
            variant="rectangular"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          />
        </div>
      ) : null}
      <div
        onClick={handleRedirect}
        style={{ display: loading ? "none" : "block" }}
        className={classes.cardContainer}
      >
        <Card className={classes.card}>
          <CardActionArea>
            <img
              src={card.productLinks[0].productUrl}
              alt={card.productName}
              width="100%"
              onLoad={imageLoaded()}
            />
            <CardContent>
              <div className={classes.titleContainer}>
                <div className={classes.title}>{card.productName}</div>
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    </>
  );
}
