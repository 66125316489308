import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  column: {
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 915px)": {
      flexWrap: "wrap",
    },
  },
  description: {
    margin: "10px 10px 10px 10px",
    width: "750px",
    "@media (max-width: 1200px)": {
      width: "500px",
    },
    "@media (max-width: 915px)": {
      width: "400px",
      fontSize: "18px",
    },
    "@media (max-width: 500px)": {
      width: "325px",
      fontSize: "18px",
    },
  },
  title: {
    width: "750px",
    "@media (max-width: 1200px)": {
      width: "500px",
    },
    "@media (max-width: 915px)": {
      width: "400px",
      fontSize: "24px",
    },
    "@media (max-width: 500px)": {
      width: "325px",
      fontSize: "24px",
    },
    textAlign: "center",
  },
});
