import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  grid: {
    margin: 0,
    width: "100%",
    "@media (max-width:650px)": {
      marginBottom: "50px",
    },
  },
});
