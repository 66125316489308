import React from "react";
import { AppBar } from "@material-ui/core";
import { Link } from "react-router-dom";
import headerImage from "./assets/header.png";
import { MenuFull, MenuMobile } from "./components";
import { useStyles } from "./styles";

export function NavBar() {
  const classes = useStyles();

  return (
    <AppBar className={classes.appBar}>
      <div className={classes.menuSmall}>
        <MenuMobile />
      </div>
      <div className={classes.container}>
        <Link to="/">
          <img className={classes.headerImg} alt="TraStar" src={headerImage} />
        </Link>
      </div>
      <div className={classes.menuFull}>
        <MenuFull />
      </div>
    </AppBar>
  );
}
