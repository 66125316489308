import React from "react";
import { useStyles } from "./styles";

export function VideoPanel({ embedId }) {
  const classes = useStyles();

  return (
    <div className={classes.videoContainer}>
      <iframe
        className={classes.video}
        height="400"
        width="2"
        src={`https://www.youtube-nocookie.com/embed/${embedId}`}
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </div>
  );
}
