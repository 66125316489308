import React from "react";
import { MenuItem, Select, InputLabel, FormControl } from "@material-ui/core";
import { useStyles } from "./styles";
import { text } from "./text";

export function SubCategoryDropDown({
  productSubcategory,
  setProductSubcategory,
  subCategoryList,
}) {
  const classes = useStyles();
  const categoryElements = subCategoryList.map((subCategoryItem) => {
    return (
      <MenuItem key={subCategoryItem} value={subCategoryItem}>
        {subCategoryItem}
      </MenuItem>
    );
  });

  return (
    <FormControl className={classes.editDropDownFormControl}>
      <InputLabel id="product-category-label">{text.subcategory}</InputLabel>
      <Select
        className={classes.editDropDown}
        labelId="product-category-label"
        value={productSubcategory}
        onChange={(e) => setProductSubcategory(e.target.value)}
      >
        {categoryElements}
      </Select>
    </FormControl>
  );
}
