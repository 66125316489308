import React, { useEffect, useState } from "react";
import { Button, Card, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { editBannerById, findAllBanners, uploadBannerImage } from "services";
import CircularProgress from "@mui/material/CircularProgress";
import { useStyles } from "./styles";
import { text } from "./text";

export function BannerAdmin() {
  const classes = useStyles();

  const [imageUploadWait, setImageUploadWait] = useState(false);
  const [snackBarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarDuration, setSnackbarDuration] = useState(6000);
  const [bannerList, setBannerList] = useState([]);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const editBannerImage = async (e) => {
    setImageUploadWait(true);
    await uploadBannerImage(e.target.files[0]).then((res) => {
      if (
        res.format.toLowerCase() !== "png" &&
        res.format.toLowerCase() !== "jpg"
      ) {
        setSnackbarMessage(text.criteria.format);
        setSnackbarSeverity(text.status.error);
        setSnackbarDuration(10000);
        setSnackbarOpen(true);
      } else if (res.width <= 3000) {
        setSnackbarMessage(text.criteria.width);
        setSnackbarSeverity(text.status.error);
        setSnackbarDuration(20000);
        setSnackbarOpen(true);
      } else if (res.height <= 1500) {
        setSnackbarMessage(text.criteria.height);
        setSnackbarSeverity(text.status.error);
        setSnackbarDuration(20000);
        setSnackbarOpen(true);
      } else if (
        res.width / res.height < 2.45 ||
        res.width / res.height > 2.55
      ) {
        setSnackbarMessage(text.criteria.ratio);
        setSnackbarSeverity(text.status.error);
        setSnackbarDuration(10000);
        setSnackbarOpen(true);
      } else {
        editBannerById(Number(e.target.id), res.secure_url).then(() => {
          callFindAllBanners();
        });
        setSnackbarMessage(text.uploaded);
        setSnackbarSeverity(text.status.success);
        setSnackbarDuration(6000);
        setSnackbarOpen(true);
      }
      setImageUploadWait(false);
    });
  };

  const callFindAllBanners = () => {
    findAllBanners().then((res) => {
      setBannerList(res.data);
    });
  };

  useEffect(() => {
    callFindAllBanners();
  }, []);

  const bannerButtons = bannerList.map((banner) => (
    <div key={banner.id} className={classes.row}>
      <img
        className={classes.img}
        alt={`Banner ${banner.id}`}
        width="40%"
        src={banner.bannerImage}
      />
      <input
        className={classes.input}
        id={`${banner.id}`}
        type="file"
        onChange={(e) => {
          editBannerImage(e);
        }}
      />
      <label htmlFor={`${banner.id}`}>
        <Button
          variant="contained"
          color="primary"
          component="span"
          disabled={imageUploadWait}
          className={classes.button}
        >
          {`${text.changeImage} ${banner.id}`}
        </Button>
      </label>
    </div>
  ));

  return (
    <div className={classes.row}>
      <Card className={classes.card}>
        <div className={classes.cardContent}>
          <h2 className={classes.header}>{text.header}</h2>
          <h3 className={classes.subHeader}>{text.subheader}</h3>
          <ul>
            <li>{text.criteria.format}</li>
            <li>{text.criteria.width}</li>
            <li>{text.criteria.height}</li>
            <li>{text.criteria.ratio}</li>
          </ul>
          {imageUploadWait ? (
            <div className={`${classes.column} ${classes.progress}`}>
              <CircularProgress size="100px" />
              <span className={classes.header}>{text.upload}</span>
              <span>{text.wait}</span>
            </div>
          ) : null}
        </div>
      </Card>
      <Card className={classes.card}>
        <div className={classes.column}>{bannerButtons}</div>
      </Card>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={snackBarOpen}
        autoHideDuration={snackbarDuration}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
