import React from "react";
import { useStyles } from "./styles";
import { text } from "./text";
import { links } from "./constants";

export function FooterCopyright() {
  const classes = useStyles();

  return (
    <div className={classes.componentContainer}>
      <div className={classes.flexGrid}>
        <div className={classes.copyrightText}>{text.copyright}</div>
        <a
          className={`${classes.link} ${classes.privacyText}`}
          target="_blank"
          rel="noreferrer"
          href={links.privacy}
        >
          {text.privacy}
        </a>
        <a
          className={`${classes.link} ${classes.termsText}`}
          target="_blank"
          rel="noreferrer"
          href={links.terms}
        >
          {text.terms}
        </a>
      </div>
    </div>
  );
}
