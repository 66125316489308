import axios from "axios";

export const sendEmail = (details) => {
  return axios.post(
    `${process.env.REACT_APP_URL}/contact`,
    {
      details,
    },
    {
      withCredentials: true,
    }
  );
};
