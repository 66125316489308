import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { useStyles } from "./styles";
import { text } from "./text";
import { icons } from "./constants";

export function ProductAssetTable({ assetArray }) {
  const classes = useStyles();

  const assetFileImage = (assetExtension) => {
    if (assetExtension === "pdf") {
      return icons.pdf;
    } else {
      return icons.text;
    }
  };

  return (
    <TableContainer className={classes.container} component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{text.header}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assetArray.map((asset) => (
            <TableRow key={asset._id}>
              <TableCell component="th" scope="row">
                <a
                  href={asset.assetUrl}
                  target="_blank"
                  rel="noreferrer"
                  className={classes.tableCell}
                >
                  <img
                    className={classes.icon}
                    alt={asset.assetExtension}
                    src={assetFileImage(asset.assetExtension)}
                  />
                  <div>{`${asset.assetName}.${asset.assetExtension}`}</div>
                </a>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
