import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  card: {
    height: 500,
    width: 500,
    textAlign: "center",
    margin: "5px 10px 5px 10px",
    border: "1px solid black",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    justifyContent: "center",
    alignContent: "center",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    height: "100%",
    marginTop: "30px",
  },
  editTextField: {
    width: "50%",
    height: "70%",
    margin: "auto 0px auto 0px",
  },
  margin: {
    display: "inline-block",
    width: "90%",
    height: "100%",
  },
  info: {
    display: "block",
    color: "#3F51B5",
    height: "100%",
    margin: "auto 0px auto 10px",
  },
  videoDimensions: {
    width: "400px",
    height: "234px",
    padding: "10px",
    alignContent: "center",
    margin: "20px auto 20px auto",
  },
  emptyDiv: {
    width: "100%",
    height: "100%",
    padding: "10px",
    alignContent: "center",
    margin: "20px auto 20px auto",
  },
});
