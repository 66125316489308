export const columns = [
  {
    field: "name",
    headerName: "File",
    width: 225,
    headerAlign: "left",
    align: "left",
  },
  {
    field: "order",
    headerName: "Order",
    width: 125,
    editable: true,
    type: "number",
    headerAlign: "left",
    align: "left",
  },
];
