import React from "react";
import { CareerSection } from "./components";
import { ProductNavigationPanel } from "../Shared";
import { useStyles } from "./styles";

export function Careers() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <div className={classes.hidden}>
          <ProductNavigationPanel />
        </div>
        <CareerSection />
      </div>
    </div>
  );
}
