import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  button: {
    textDecoration: "none",
    color: "rgb(130, 130, 130)",
    fontSize: "20px",
    fontWeight: "bold",
    margin: "0 1rem",
    whiteSpace: "nowrap",
  },
  toolbar: {
    alignItems: "end",
  },
});
