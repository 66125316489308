import React from "react";
import { Button, Card } from "@material-ui/core";
import { ImagePanel } from "./components";
import { useStyles } from "./styles";
import { text } from "./text";

export function EditProductImage({
  addProductImage,
  productLinks,
  gridSelection,
  selectedProduct,
  deleteImage,
  imageUploadWait,
  handleClickAwayGrid,
  handleOrderImageChange,
}) {
  const classes = useStyles();

  return (
    <div className={classes.column}>
      <Card className={classes.card}>
        <div className={classes.column}>
          <input
            className={classes.input}
            id="image-upload"
            type="file"
            onChange={(e) => {
              addProductImage(e);
            }}
          />
          <label htmlFor="image-upload">
            <Button
              className={classes.uploadButton}
              variant="contained"
              color="primary"
              component="span"
            >
              {text.upload}
            </Button>
          </label>
          <ImagePanel
            handleClickAwayGrid={handleClickAwayGrid}
            productLinks={productLinks}
            selectedProduct={selectedProduct}
            gridSelection={gridSelection}
            deleteImage={deleteImage}
            handleOrderImageChange={handleOrderImageChange}
            imageUploadWait={imageUploadWait}
          />
        </div>
      </Card>
    </div>
  );
}
