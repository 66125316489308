export const routes = {
  home: "/",
  products: "/products",
  cases: "/cases",
  about: "/about",
  contact: "/contact",
  profile: "/profile",
  productAdmin: "/productadmin",
  logout: "/logout",
  userAdmin: "/admin",
};

export const menuItems = [
  {
    label: "Home",
    route: routes.home,
  },
  {
    label: "Products",
    route: routes.products,
  },
  {
    label: "Case Studies",
    route: routes.cases,
  },
  {
    label: "About",
    route: routes.about,
  },
  {
    label: "Contact",
    route: routes.contact,
  },
];

export const adminMenuItems = [
  {
    label: "Profile",
    route: routes.profile,
  },
  {
    label: "Product Admin",
    route: routes.productAdmin,
  },
  {
    label: "Logout",
    route: routes.logout,
  },
];

export const superAdminMenuItems = [
  {
    label: "User Admin",
    route: routes.userAdmin,
  },
];
