import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  flexGrid: {
    display: "flex",
    alignContent: "space-around",
    justifyContent: "space-around",
    flexDirection: "row",
  },
  featureImage: {
    opacity: "1",
    width: "100%",
    height: "100%",
    display: "block",
    margin: "auto",
    transition: ".5s ease",
    padding: "1%",
    backfaceVisibility: "hidden",
  },
  featuredHeader: {
    color: "#110884",
    textAlign: "center",
    marginBottom: "2%",
    fontSize: "2vw",
    "@media (max-width:1400px)": {
      fontSize: "2.5vw",
    },
    "@media (max-width:1000px)": {
      fontSize: "3.5vw",
    },
    "@media (max-width:600px)": {
      fontSize: "4.5vw",
    },
  },
  componentContainer: {
    padding: "2% 5% 2% 5%",
  },
  middle: {
    transition: ".5s ease",
    opacity: "0",
    position: "absolute",
    top: "85%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    msTransform: "translate(-50%, -50%)",
    textAlign: "center",
    width: "75%",
  },
  text: {
    backgroundColor: "#110884",
    color: "white",
    fontSize: "1rem",
    padding: "2% 4%",
    "@media (max-width:650px)": {
      fontSize: ".75rem",
    },
    "@media (max-width:475px)": {
      fontSize: ".5rem",
    },
    "@media (max-width:310px)": {
      fontSize: ".25rem",
    },
  },
  imageContainer: {
    position: "relative",
    "&:hover": {
      "& img": {
        opacity: "0.3",
        cursor: "pointer",
      },
      "& div": {
        opacity: "1",
        cursor: "pointer",
      },
    },
  },
});
