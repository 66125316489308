import React from "react";
import { Link } from "react-router-dom";
import { useStyles } from "./styles";

export function ListItems({ items }) {
  const classes = useStyles();

  const listMarkup = items.map((item) => {
    const bulletMarkup = (
      <li key={item.text} className={classes.bullet}>
        <div className={classes.blank}></div>
        <span>{item.text}</span>
      </li>
    );

    if (item.link) {
      if (item.external) {
        return (
          <Link
            key={`${item.text}_link`}
            className={classes.link}
            to={{ pathname: item.link }}
            target="_blank"
          >
            {bulletMarkup}
          </Link>
        );
      }
      return (
        <Link key={`${item.text}_link`} className={classes.link} to={item.link}>
          {bulletMarkup}
        </Link>
      );
    }
    return bulletMarkup;
  });

  return listMarkup;
}
