import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  flexGrid: {
    display: "flex",
    alignContent: "space-around",
    justifyContent: "space-around",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
  },
  logoImage: {
    width: "15%",
    "@media (max-width:650px)": {
      width: "25%",
    },
    display: "block",
    margin: "auto",
  },
  dlcLogoImage: {
    width: "10%",
    "@media (max-width:650px)": {
      width: "15%",
    },
    display: "block",
    margin: "auto",
  },
  header: {
    textAlign: "center",
    marginBottom: "2%",
    color: "#110884",
    fontSize: "2vw",
    "@media (max-width:1400px)": {
      fontSize: "2.5vw",
    },
    "@media (max-width:1000px)": {
      fontSize: "3.5vw",
    },
    "@media (max-width:600px)": {
      fontSize: "4.5vw",
    },
  },
  componentContainer: {
    padding: "2% 5% 2% 5%",
    backgroundColor: "#F0F0F5",
  },
});
