import React from "react";
import { Typography, Breadcrumbs, Link } from "@material-ui/core";
import { text } from "./text";
import { useStyles } from "./styles";

export function ProductBreadcrumbs({ breadcrumb, url, errorStatus }) {
  const classes = useStyles();

  let allProductsCrumb;
  let categoryCrumb;
  let subcategoryCrumb;
  let productCrumb;
  let crumbLength = Object.values(breadcrumb).filter((x) => x !== null).length;

  if (crumbLength === 0) {
    allProductsCrumb = (
      <Typography color="textPrimary">{text.products}</Typography>
    );
  } else if (crumbLength > 0) {
    allProductsCrumb = (
      <Link color="inherit" href={`${url}`}>
        {text.products}
      </Link>
    );
  }

  if (crumbLength === 1) {
    categoryCrumb = (
      <Typography color="textPrimary">{breadcrumb.category}</Typography>
    );
  } else if (crumbLength > 1) {
    categoryCrumb = (
      <Link
        color="inherit"
        href={`${url}?category=${encodeURIComponent(breadcrumb.category)}`}
      >
        {breadcrumb.category}
      </Link>
    );
  }

  if (crumbLength === 2) {
    subcategoryCrumb = (
      <Typography color="textPrimary">{breadcrumb.subcategory}</Typography>
    );
  } else if (crumbLength > 2) {
    subcategoryCrumb = (
      <Link
        color="inherit"
        href={`${url}?subcategory=${encodeURIComponent(
          breadcrumb.subcategory
        )}`}
      >
        {breadcrumb.subcategory}
      </Link>
    );
  }

  if (crumbLength === 3) {
    productCrumb = (
      <Typography color="textPrimary">{breadcrumb.product}</Typography>
    );
  }

  return (
    <>
      {!errorStatus ? (
        <div className={classes.container}>
          <Breadcrumbs aria-label="breadcrumb">
            {allProductsCrumb}
            {categoryCrumb}
            {subcategoryCrumb}
            {productCrumb}
          </Breadcrumbs>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}
