import React from "react";
import { Card } from "@material-ui/core";
import { useStyles } from "./styles";
import { VideoTabs } from "./components";

export function EditProductVideo({ productVideoId, setProductVideoId }) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <VideoTabs
        productVideoId={productVideoId}
        setProductVideoId={setProductVideoId}
      />
    </Card>
  );
}
