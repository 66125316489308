import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useStyles } from "./styles";
import { text } from "./text";
import error from "./assets/error.png";

export function NotFound() {
  const classes = useStyles();
  return (
    <div className={classes.row}>
      <img className={classes.image} src={error} alt={text.status} />
      <div className={classes.center}>
        <h1 className={classes.header}>{text.header}</h1>
        <h2 className={classes.subheader}>{text.subheader}</h2>
        <Link className={classes.link} to="/">
          <Button variant="contained" className={classes.button}>
            {text.button}
          </Button>
        </Link>
      </div>
    </div>
  );
}
