import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  root: {
    height: "100%",
    margin: 20,
  },
  container: {
    minWidth: "300px",
    width: "400px",
    marginRight: "50px",
  },
  categoryItem: {
    fontWeight: 700,
  },
  allProductsText: {
    fontSize: "25px",
  },
});
